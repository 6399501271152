import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignment6',
  templateUrl: './assignment6.component.html',
  styleUrls: ['./assignment6.component.scss']
})
export class Assignment6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
