<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Categories</li>
            </ul>
            <h2>Categories</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img1.jpg" alt="image">
                    <div class="content">
                        <h3>Development</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img2.jpg" alt="image">
                    <div class="content">
                        <h3>Business</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img3.jpg" alt="image">
                    <div class="content">
                        <h3>IT & Software</h3>
                        <span>15 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img4.jpg" alt="image">
                    <div class="content">
                        <h3>Design</h3>
                        <span>11 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img5.jpg" alt="image">
                    <div class="content">
                        <h3>Lifestyle</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img6.jpg" alt="image">
                    <div class="content">
                        <h3>Photo & Flim</h3>
                        <span>12 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img7.jpg" alt="image">
                    <div class="content">
                        <h3>Animation</h3>
                        <span>05 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img8.jpg" alt="image">
                    <div class="content">
                        <h3>Writing</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img9.jpg" alt="image">
                    <div class="content">
                        <h3>Python</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img10.jpg" alt="image">
                    <div class="content">
                        <h3>Data Science</h3>
                        <span>12 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img11.jpg" alt="image">
                    <div class="content">
                        <h3>Machine Learning</h3>
                        <span>05 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/categories/img12.jpg" alt="image">
                    <div class="content">
                        <h3>Web Development</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>
