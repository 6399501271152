<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Admission Calender</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <!--<h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Fee Structure (Applicable from Academic Session 2022-23 onwards)</h4>-->
                    <style>
                        p {
                            text-align: justify
                        }
                    </style>
                    <div class="about-text-container">
                        <div class="accor_heading downToggle">
                            <table cellspacing="0" cellpadding="0">
                                <colgroup>
                                    <col width="49">
                                    <col width="54">
                                    <col width="405">
                                    <col width="310">
                                </colgroup>
                                <tbody>
                                <tr height="40">
                                    <td height="40" width="52">S.No.</td>
                                    <td width="82">UG/PG</td>
                                    <td width="405">Programme Name</td>
                                    <td width="310">Specilization</td>
                                    <td width="88">Annual Fee in Rupees</td>
                                </tr>
                                <tr height="20">
                                    <td rowspan="8" height="160">1</td>
                                    <td rowspan="8">UG</td>
                                    <td rowspan="8">BACHELOR OF ARTS</td>
                                    <td>GENERAL(Combination of any 3 Subjects)</td>
                                    <td rowspan="8" width="88">8000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; HINDI</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ENGLISH</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; POLITICAL SCIENCE</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; HISTORY</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ECONOMICS</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SOCIOLOGY</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MATHEMATICS</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">2</td>
                                    <td>UG</td>
                                    <td>BACHELOR OF LIBRARY AND INFORMATION SCIENCES</td>
                                    <td>LIBRARY AND INFORMATION SCIENCES</td>
                                    <td width="88">14000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">3</td>
                                    <td>UG</td>
                                    <td>BACHELOR OF BUSINESS ADMINISTRATION</td>
                                    <td>MANAGEMENT</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">4</td>
                                    <td>UG&nbsp;</td>
                                    <td>BACHELOR OF COMMERCE</td>
                                    <td>MANAGEMENT</td>
                                    <td width="88">8000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">5</td>
                                    <td>UG&nbsp;</td>
                                    <td>BACHELOR OF COMMERCE (HONORS)</td>
                                    <td>MANAGEMENT</td>
                                    <td width="88">10000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">6</td>
                                    <td>UG</td>
                                    <td>BACHELOR OF ARTS (JMC)</td>
                                    <td>JOURNALISM AND MASS COMMUNICATION</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">7</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>BUDDHIST STUDIES</td>
                                    <td width="88">13000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">8</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>EDUCATION</td>
                                    <td width="88">14400</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">9</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>PUBLIC ADMINISTRATION</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">10</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>HOME SCIENCE</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">11</td>
                                    <td>PG</td>
                                    <td>MASTER OF LIBRARY AND INFORMATION SCIENCES</td>
                                    <td>LIBRARY AND INFORMATION SCIENCES</td>
                                    <td width="88">18000</td>
                                </tr>
                                <tr height="20">
                                    <td rowspan="6" height="120">12</td>
                                    <td rowspan="6">PG</td>
                                    <td rowspan="6">MASTER OF BUSINESS ADMINISTRATION</td>
                                    <td>GENERAL(Combination of any 2 Subjects)</td>
                                    <td rowspan="6" width="88">24000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">1. INFORMATION TECHNOLOGY</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">2. HUMAN RESOURCE MANAGEMENT</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">3. MARKETING MANAGEMENT</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">4. FINANCIAL MANAGEMENT</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">5. FASHION DESIGNING</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">13</td>
                                    <td>PG</td>
                                    <td>MASTER OF COMMECE</td>
                                    <td>MANAGEMENT</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">14</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS (JMC)</td>
                                    <td>JOURNALISM AND MASS COMMUNICATION</td>
                                    <td width="88">14000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">15</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>POLITICAL SCIENCES</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">16</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>MATHEMATICS</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">17</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>SOCIOLOGY</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">18</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>HISTORY</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">19</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>HINDI</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">20</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>ECONOMICS</td>
                                    <td width="88">12000</td>
                                </tr>
                                <tr height="20">
                                    <td height="20">21</td>
                                    <td>PG</td>
                                    <td>MASTER OF ARTS</td>
                                    <td>ENGLISH</td>
                                    <td width="88">12000</td>
                                </tr>
                                </tbody>
                            </table>


                            <!-- <table border="1" cellspacing="0" cellpadding="0">
                <tr>
                <td width="575" colspan="3" valign="top"><p align="center"><strong>Fee Structure for A 2020-21 and C 2021</strong></p></td>
                </tr>
                <tr>
                <td width="575" colspan="3" valign="top"><p align="center">All students are hereby informed that the Directorate of Distance Education, SVSU offering Scholarship discount for Academic Session    2020- 21, in view    of COVID-19 Pandemic.</p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="left"><strong>S.No.</strong></p></td>
                <td width="385" valign="top"><p align="left"><strong>Course Name </strong></p></td>
                <td width="124" valign="top"><p align="center"><strong>Semester Fees</strong></p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">1 </p></td>
                <td width="385" valign="top"><p align="left">DBA </p></td>
                <td width="124" valign="top"><p align="center">4500 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">2 </p></td>
                <td width="385" valign="top"><p align="left">DCA </p></td>
                <td width="124" valign="top"><p align="center">4600 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">3 </p></td>
                <td width="385" valign="top"><p align="left">ADBA </p></td>
                <td width="124" valign="top"><p align="center">4500 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">4 </p></td>
                <td width="385" valign="top"><p align="left">ADCA </p></td>
                <td width="124" valign="top"><p align="center">4600 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">5 </p></td>
                <td width="385" valign="top"><p align="left">BA (FASHION DESIGNING) </p></td>
                <td width="124" valign="top"><p align="center">4600 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">6 </p></td>
                <td width="385" valign="top"><p align="left">BA (FINE ARTS) </p></td>
                <td width="124" valign="top"><p align="center">4600 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">7 </p></td>
                <td width="385" valign="top"><p align="left">BA - ALL </p></td>
                <td width="124" valign="top"><p align="center">1750 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">8 </p></td>
                <td width="385" valign="top"><p align="left">BA (JMC) </p></td>
                <td width="124" valign="top"><p align="center">4600 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">9 </p></td>
                <td width="385" valign="top"><p align="left">BCOM </p></td>
                <td width="124" valign="top"><p align="center">2500 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">10 </p></td>
                <td width="385" valign="top"><p align="left">BLIB </p></td>
                <td width="124" valign="top"><p align="center">6000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">11 </p></td>
                <td width="385" valign="top"><p align="left">BSC (PCM) </p></td>
                <td width="124" valign="top"><p align="center">6000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">12 </p></td>
                <td width="385" valign="top"><p align="left">BSC (ZBC) </p></td>
                <td width="124" valign="top"><p align="center">6000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">13 </p></td>
                <td width="385" valign="top"><p align="left">BBA </p></td>
                <td width="124" valign="top"><p align="center">5000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">14 </p></td>
                <td width="385" valign="top"><p align="left">BCA </p></td>
                <td width="124" valign="top"><p align="center">6800 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">15 </p></td>
                <td width="385" valign="top"><p align="left">MA (JMC)</p></td>
                <td width="124" valign="top"><p align="center">6000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">16 </p></td>
                <td width="385" valign="top"><p align="left">MA (ALL) </p></td>
                <td width="124" valign="top"><p align="center">4500 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">17 </p></td>
                <td width="385" valign="top"><p align="left">MLIB </p></td>
                <td width="124" valign="top"><p align="center">6000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">18 </p></td>
                <td width="385" valign="top"><p align="left">MSC (CA) </p></td>
                <td width="124" valign="top"><p align="center">7200 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">19 </p></td>
                <td width="385" valign="top"><p align="left">MSC (DFSM) </p></td>
                <td width="124" valign="top"><p align="center">6200 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">20 </p></td>
                <td width="385" valign="top"><p align="left">MCA </p></td>
                <td width="124" valign="top"><p align="center">7200 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">21 </p></td>
                <td width="385" valign="top"><p align="left">MCOM</p></td>
                <td width="124" valign="top"><p align="center">3000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">22 </p></td>
                <td width="385" valign="top"><p align="left">MA (EDUCATION)</p></td>
                <td width="124" valign="top"><p align="center">7200 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">23 </p></td>
                <td width="385" valign="top"><p align="left">MBA (PHARMACEUTICAL MARKETING) </p></td>
                <td width="124" valign="top"><p align="center">9000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">24 </p></td>
                <td width="385" valign="top"><p align="left">MBA (HOSPITAL ADMINISTRATION) </p></td>
                <td width="124" valign="top"><p align="center">9000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">25 </p></td>
                <td width="385" valign="top"><p align="left">MBA (FASHION DESIGNING) </p></td>
                <td width="124" valign="top"><p align="center">9000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">26 </p></td>
                <td width="385" valign="top"><p align="left">MBA (FINANCE,    HR, MARKETING, IT, P&amp;OM) - With Dual <br />
                Specialization</p></td>
                <td width="124" valign="top"><p align="center">8200 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">27 </p></td>
                <td width="385" valign="top"><p align="left">PGDMCH </p></td>
                <td width="124" valign="top"><p align="center">10000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">28 </p></td>
                <td width="385" valign="top"><p align="left">PGCPP </p></td>
                <td width="124" valign="top"><p align="center">4500 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">29 </p></td>
                <td width="385" valign="top"><p align="left">PGDDPN </p></td>
                <td width="124" valign="top"><p align="center">7200 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">30 </p></td>
                <td width="385" valign="top"><p align="left">PGDIPR </p></td>
                <td width="124" valign="top"><p align="center">4500 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">31 </p></td>
                <td width="385" valign="top"><p align="left">PGDCA </p></td>
                <td width="124" valign="top"><p align="center">7200 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">32 </p></td>
                <td width="385" valign="top"><p align="left">PGDHHM </p></td>
                <td width="124" valign="top"><p align="center">10000 </p></td>
                </tr>
                <tr>
                <td width="66" valign="top"><p align="center">33 </p></td>
                <td width="385" valign="top"><p align="left">PGDFSQM </p></td>
                <td width="124" valign="top"><p align="center">7200 </p></td>
                </tr>
                </table>
                -->
                        </div>
                        <br>
                        <br>
                        <div>
                            <p>
                                <b>Other Fees: </b>
                            </p>


                            <p><strong>In addition to the course fee, the learner will be required to deposit the
                                following fee at appropriate</strong></p><br>
                            <p>One-time Registration fee: Rs. 1500 (Non refundable &amp; Non Transferable)</p>
                            <p></p> Examination Fee : Rs. 3000 per Annum <p></p>
                            <p>Reappear Paper Fee : Rs. 250 per paper</p>
                            <p>Credit Transfer Fee : Rs. 1500 </p>
                            <p>Continuation Fee : Rs. 2000</p>
                            <p></p> Re-Totaling Fee : Rs. 200 per paper <p></p>
                            <p>Change of Address Fee : Rs. 500 </p>
                            <p>Change of Specialization Fee : Rs. 500</p>
                            <p></p> Certificate/ Diploma/ Provisional Degree Fee : Rs. 500 <p></p>
                            <p>Consolidated Marksheet Fee : Rs. 1000</p>
                            <p></p> Duplicate Marksheet Fee : Rs. 500 <p></p>
                            <p>Final Degree Fee : Rs. 1500 </p>
                            <p>Confidential Result Fee : Rs. 1000</p>
                            <p></p>Document Verification Fee : Rs. 1000 (Per Document for non Gov. Organizations)
                            <p></p>
                            <p>Transcript Fee : Rs. 1000 for each</p>
                            <p></p>Degree Correction : Rs. 300<p></p>
                            <p></p> Marksheet Correction Fee : Rs. 300 <p></p>
                            <p></p>
                            <h3>Note : Fee refund policy as per UGC norms</h3>
                            <p><strong>No any other hidden charges</strong></p>
                        </div>
                        <div style="padding-top:20px">
                            Student can submit his fee in cash to cash counter in department or submit online<br>
                            <b>For online payment, Accouts Details are as follow :</b> <br><br>
                            <table style="width:100%" cellpadding="0" cellspacing="0" border="1">
                                <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>IFSC Code:</b> ICIC0006285
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Harpur Road Meerut
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
