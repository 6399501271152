<div class="funfacts-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="80">00</span>+</h3>
                    <p>80K + ALUMNI</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="800">00</span>+</h3>
                    <p>800 + FACULTY</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="15">00</span>+</h3>
                    <p>15 + HOSTELS</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="8">00</span>+</h3>
                    <p>8K + STUDENTS</p>
                </div>
            </div>
        </div>
    </div>
</div>
