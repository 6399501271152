<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Guidelines</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>Guidelines</h3>
                        <p>
                            <a href="https://subhartidde.com/documents/Assignment%20Report.pdf" target="_blank">1.
                                Assignment
                                Report</a>
                        </p>
                        <p>
                            <a href="https://subhartidde.com/documents/Dissertation.pdf" target="_blank">2.
                                Dissertation</a>
                        </p>
                        <p>
                            <a href="https://subhartidde.com/documents/BBA-Project%20Guideline.pdf" target="_blank">3.
                                BBA Project</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
