import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-semester-syllabus',
  templateUrl: './semester-syllabus.component.html',
  styleUrls: ['./semester-syllabus.component.scss']
})
export class SemesterSyllabusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
