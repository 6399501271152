<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>ASSIGNMENTS</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">

                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Assignment's of Academic Batch-2021-22-I Semester</h4>

                    <style>

                        p { text-align:justify }

                    </style>

                    <div class="rightcategory_box">

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/B.COM.pdf" style="font-size:18px; font-weight:400">Assignments for B.COM</a>

                                </h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/B.LIB.pdf" style="font-size:18px; font-weight:400"> Assignments for B.LIB</a> </h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-ECONOMICS.pdf" style="font-size:18px; font-weight:400"> Assignments for BA-ECONOMICS</a>  </h6>



                            </div>

                        </div>



                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-ENGLISH.pdf" style="font-size:18px; font-weight:400"> Assignment for BA-ENGLISH</a></h6>





                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-HINDI.pdf" style="font-size:18px; font-weight:400">Assignment for BA-HINDI</a></h6>



                            </div>

                        </div>



                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-HISTRY.pdf" style="font-size:18px; font-weight:400"> Assignment for BA-HISTRY</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-JMC.pdf" style="font-size:18px; font-weight:400"> Assignment for BA-JMC</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-MATHEMATICS.pdf" style="font-size:18px; font-weight:400"> Assignment for BA-MATH</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-POLITICAL%20SCIENCE.pdf" style="font-size:18px; font-weight:400">Assignment for BA-POLITICAL SCIENCE</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BA-SOCIOLOGY.pdf" style="font-size:18px; font-weight:400">Assignment for BA-SOCIOLOGY</a></h6>



                            </div>

                        </div>



                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/BBA.pdf" style="font-size:18px; font-weight:400"> Assignments for BBA</a></h6>



                            </div>

                        </div>



                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/M.COM.pdf" style="font-size:18px; font-weight:400"> Assignments for M.COM</a></h6>



                            </div>

                        </div>



                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/M.LIB.pdf" style="font-size:18px; font-weight:400"> Assignments for M.LIB</a></h6>



                            </div>

                        </div>



                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MA-ENGLISH.pdf" style="font-size:18px; font-weight:400"> Assignments for MA-ENGLISH</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MA-HINDI.pdf" style="font-size:18px; font-weight:400"> Assignments for MA-HINDI</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MA-HISTRY.pdf" style="font-size:18px; font-weight:400"> Assignments for MA-HISTORY</a></h6>



                            </div>

                        </div>



                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MA-JMC.pdf" style="font-size:18px; font-weight:400"> Assignments for MA-JMC</a></h6>



                            </div>

                        </div>


                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MA-POLITICAL%20SCIENCE.pdf" style="font-size:18px; font-weight:400"> Assignments for MA-POLITICAL SCIENCE</a></h6>



                            </div>

                        </div>


                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MA-PUBLIC%20ADMINISTRATION.pdf" style="font-size:18px; font-weight:400"> Assignments for MA-PUBLIC ADMINISTRATION</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MA-SOCIOLOGY.pdf" style="font-size:18px; font-weight:400"> Assignments for MA-SOCIOLOGY</a></h6>



                            </div>

                        </div>

                        <div class="blog_box">

                            <div class="blog_heading">

                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">

                                    <a href="Assigment_1Sem/MBA.pdf" style="font-size:18px; font-weight:400"> Assignments for MBA</a></h6>



                            </div>

                        </div>



                        <br class="clear">

                    </div>

                    <style>

                        /*

            Generic Styling, for Desktops/Laptops

            */

                        table {

                            width: 100%;

                            border-collapse: collapse;

                        }

                        /* Zebra striping */

                        tr:nth-of-type(odd) {

                            background: #eee;

                        }

                        th {

                            background: #333;

                            color: white;

                            font-weight: bold;

                        }

                        td, th {

                            padding: 6px;

                            border: 1px solid #ccc;

                            text-align: left;

                        }

                        /*

                        Max width before this PARTICULAR table gets nasty

                        This query will take effect for any screen smaller than 760px

                        and also iPads specifically.

                        */

                        @media

                        only screen and (max-width: 760px),

                        (min-device-width: 768px) and (max-device-width: 1024px)  {



                            /* Force table to not be like tables anymore */

                            table, thead, tbody, th, td, tr {

                                display: block;

                            }



                            /* Hide table headers (but not display: none;, for accessibility) */

                            thead tr {

                                position: absolute;

                                top: -9999px;

                                left: -9999px;

                            }



                            tr { border: 1px solid #ccc; }



                            td {

                                /* Behave  like a "row" */

                                border: none;

                                border-bottom: 1px solid #eee;

                                position: relative;

                                padding-left: 50%;

                            }



                            td:before {

                                /* Now like a table header */

                                position: absolute;

                                /* Top/left values mimic padding */

                                top: 6px;

                                left: 6px;

                                width: 45%;

                                padding-right: 10px;

                                white-space: nowrap;

                            }



                            /*

                            Label the data

                            */

                            td:nth-of-type(1):before { content: "First Name"; }

                            td:nth-of-type(2):before { content: "Last Name"; }

                            td:nth-of-type(3):before { content: "Job Title"; }

                            td:nth-of-type(4):before { content: "Favorite Color"; }

                            td:nth-of-type(5):before { content: "Wars of Trek?"; }

                            td:nth-of-type(6):before { content: "Secret Alias"; }

                            td:nth-of-type(7):before { content: "Date of Birth"; }

                            td:nth-of-type(8):before { content: "Dream Vacation City"; }

                            td:nth-of-type(9):before { content: "GPA"; }

                            td:nth-of-type(10):before { content: "Arbitrary Data"; }

                        }

                    </style>

                </div>
            </div>
        </div>
    </div>
</div>

