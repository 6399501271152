import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-co-ordinators',
  templateUrl: './course-co-ordinators.component.html',
  styleUrls: ['./course-co-ordinators.component.scss']
})
export class CourseCoOrdinatorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
