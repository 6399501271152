<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>COURSES</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<!--<div class="events-area bg-f9f9f9 pt-70" style="margin-top: -50px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-8">
                <div class="about-text-container">
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">All Courses</h4>
                    <div class="rightcategory_box">
                        <div class="course_accordion">
                            <div class="course_box">
                                <div class="accor_heading downToggle">
                                    <table cellspacing="0" cellpadding="0">
                                        <tbody>
                                        <tr height="59">
                                            <th rowspan="2" height="80" width="49">S. No.</th>
                                            <th rowspan="2" width="54">LEVEL</th>
                                            <th rowspan="2" width="405">PROGRAMME</th>
                                            <th rowspan="2" width="310">STREAM</th>
                                        </tr>
                                        <tr height="21"></tr>
                                        <tr height="21">
                                            <td rowspan="8" height="168">1</td>
                                            <td rowspan="8">UG</td>
                                            <td rowspan="8">BACHELOR OF ARTS</td>
                                            <td>GENERAL(Combination of any 3 Subjects)</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; HINDI</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ENGLISH</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; POLITICAL
                                                SCIENCE
                                            </td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; HISTORY</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ECONOMICS</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SOCIOLOGY</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MATHEMATICS</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">2</td>
                                            <td>UG</td>
                                            <td>BACHELOR OF LIBRARY AND INFORMATION SCIENCES</td>
                                            <td>LIBRARY AND INFORMATION SCIENCES</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">3</td>
                                            <td>UG</td>
                                            <td>BACHELOR OF BUSINESS ADMINISTRATION</td>
                                            <td>MANAGEMENT</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">4</td>
                                            <td>UG&nbsp;</td>
                                            <td>BACHELOR OF COMMERCE</td>
                                            <td>MANAGEMENT</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">5</td>
                                            <td>UG&nbsp;</td>
                                            <td>BACHELOR OF COMMERCE (HONORS)</td>
                                            <td>MANAGEMENT</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">6</td>
                                            <td>UG</td>
                                            <td>BACHELOR OF ARTS</td>
                                            <td>JOURNALISM AND MASS COMMUNICATION</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">7</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>BUDDHIST STUDIES</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">8</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>EDUCATION</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">9</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>PUBLIC ADMINISTRATION</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">10</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>HOME SCIENCE</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">11</td>
                                            <td>PG</td>
                                            <td>MASTER OF LIBRARY AND INFORMATION SCIENCES</td>
                                            <td>LIBRARY AND INFORMATION SCIENCES</td>
                                        </tr>
                                        <tr height="21">
                                            <td rowspan="6" height="126">12</td>
                                            <td rowspan="6">PG</td>
                                            <td rowspan="6">MASTER OF BUSINESS ADMINISTRATION</td>
                                            <td>GENERAL(Combination of any 2 Subjects)</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">1. INFORMATION TECHNOLOGY</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">2. HUMAN RESOURCE MANAGEMENT</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">3. MARKETING MANAGEMENT</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">4. FINANCIAL MANAGEMENT*</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">5. FASHION DESIGNING</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">13</td>
                                            <td>PG</td>
                                            <td>MASTER OF COMMECE</td>
                                            <td>MANAGEMENT</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">14</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>JOURNALISM AND MASS COMMUNICATION</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">15</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>POLITICAL SCIENCES</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">16</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>MATHEMATICS</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">17</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>SOCIOLOGY</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">18</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>HISTORY</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">19</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>HINDI</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">20</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>ECONOMICS</td>
                                        </tr>
                                        <tr height="21">
                                            <td height="21">21</td>
                                            <td>PG</td>
                                            <td>MASTER OF ARTS</td>
                                            <td>ENGLISH</td>
                                        </tr>
                                        <tr height="20">
                                            <td height="20"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr height="20">
                                            <td colspan="4" height="20">* Fashiod Design will be applicable in the
                                                combination with one of the specilization from HR, Marketing and
                                                Finance&nbsp;
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <br>
                                <div>
                                    <p>
                                        <b>*</b> Minimum Age of a learner for eligibility for a perticular course of
                                        progrmme should be 17 Years in the month of December for respective Academic
                                        Session.

                                    </p>
                                    <p>
                                        <b>*</b> Maximum duration refers to the Maximum time allowed for the
                                        completion
                                        of that course. If the student fails to complete it within <br>this period
                                        full fees will have to be paid again.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <style>
                        /*
            Generic Styling, for Desktops/Laptops
            */
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }

                        /* Zebra striping */
                        tr:nth-of-type(odd) {
                            background: #eee;
                        }

                        th {
                            background: #333;
                            color: white;
                            font-weight: bold;
                        }

                        td, th {
                            padding: 6px;
                            border: 1px solid #ccc;
                            text-align: left;
                        }

                        /*
                        Max width before this PARTICULAR table gets nasty
                        This query will take effect for any screen smaller than 760px
                        and also iPads specifically.
                        */
                        @media only screen and (max-width: 760px),
                        (min-device-width: 768px) and (max-device-width: 1024px) {

                            /* Force table to not be like tables anymore */
                            table, thead, tbody, th, td, tr {
                                display: block;
                            }

                            /* Hide table headers (but not display: none;, for accessibility) */
                            thead tr {
                                position: absolute;
                                top: -9999px;
                                left: -9999px;
                            }

                            tr {
                                border: 1px solid #ccc;
                            }

                            td {
                                /* Behave  like a "row" */
                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                padding-left: 50%;
                            }

                            td:before {
                                /* Now like a table header */
                                position: absolute;
                                /* Top/left values mimic padding */
                                top: 6px;
                                left: 6px;
                                width: 45%;
                                padding-right: 10px;
                                white-space: nowrap;
                            }

                            /*
                            Label the data
                            */
                            td:nth-of-type(1):before {
                                content: "First Name";
                            }

                            td:nth-of-type(2):before {
                                content: "Last Name";
                            }

                            td:nth-of-type(3):before {
                                content: "Job Title";
                            }

                            td:nth-of-type(4):before {
                                content: "Favorite Color";
                            }

                            td:nth-of-type(5):before {
                                content: "Wars of Trek?";
                            }

                            td:nth-of-type(6):before {
                                content: "Secret Alias";
                            }

                            td:nth-of-type(7):before {
                                content: "Date of Birth";
                            }

                            td:nth-of-type(8):before {
                                content: "Dream Vacation City";
                            }

                            td:nth-of-type(9):before {
                                content: "GPA";
                            }

                            td:nth-of-type(10):before {
                                content: "Arbitrary Data";
                            }
                        }
                    </style>
                </div>
            </div>
            <div class="col-md-4">


                <div class="sidebar-widget">
                    <div class="single-sidebar-widget">
                        <div class="tution-wrapper">
                            <div class="tution-fee">
                                <h1>Quick Links</h1>
                            </div>
                            <style>
                                .sub-menu li {
                                    padding: 10px;
                                    width: 100%;
                                    border-bottom: solid 1px #ccc;
                                    margin: 0;
                                }
                            </style>
                            <ul class="sub-menu">
                                <li><a routerLink="/all-courses"><span>All Courses</span></a></li>
                                <li><a routerLink="/diploma"><span>Diploma Courses</span></a></li>
                                <li><a routerLink="/advanced-diploma"><span>Advanced Diploma</span></a></li>
                                <li><a routerLink="/degree-courses"><span>Degree Courses</span></a></li>
                                <li><a routerLink="/pg-courses"><span>PG Courses</span></a></li>
                                <li><a routerLink="/pg-diploma-courses"><span>PG Diploma Courses</span></a></li>
                                <li><a routerLink="/new-courses"><span>New Courses</span></a></li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>-->
<div class="container" style="margin-top: 25px;">
    <div class="row align-items-center">
        <div class="col-md-8">
            <div style="overflow-x:auto;">
                <table style="border: 1px solid black;">
                    <tr>
                        <th>S. No.</th>
                        <th>LEVEL</th>
                        <th>PROGRAMME</th>
                        <th>STREAM</th>
                    </tr>
                    <tr style="">
                        <td>1</td>
                        <td>UG</td>
                        <td>BACHELOR OF ARTS</td>
                        <td>
                            <h6>GENERAL(Combination of any 3 Subjects)</h6>
                            <ol>
                                <li>
                                    HINDI
                                </li>
                                <li>
                                    English
                                </li>
                                <li>POLITICAL SCIENCE</li>
                                <li>ECONOMICS</li>
                                <li>SOCIOLOGY</li>
                                <li>MATHEMATICS</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>UG</td>
                        <td>BACHELOR OF LIBRARY AND INFORMATION SCIENCES</td>
                        <td>LIBRARY AND INFORMATION SCIENCES</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>UG</td>
                        <td>BACHELOR OF BUSINESS ADMINISTRATION</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>UG</td>
                        <td>BACHELOR OF COMMERCE</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>UG</td>
                        <td>BACHELOR OF COMMERCE (HONORS)</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>UG</td>
                        <td>BACHELOR OF ARTS</td>
                        <td>JOURNALISM AND MASS COMMUNICATION</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>BUDDHIST STUDIES</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>EDUCATION</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>PUBLIC ADMINISTRATION</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>HOME SCIENCE</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>PG</td>
                        <td>MASTER OF LIBRARY AND INFORMATION SCIENCES</td>
                        <td>LIBRARY AND INFORMATION SCIENCES</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>PG</td>
                        <td>MASTER OF BUSINESS ADMINISTRATION</td>
                        <td>
                            <ol>
                                <p>GENERAL(Combination of any 2 Subjects)</p>
                                <li>INFORMATION TECHNOLOGY</li>
                                <li>HUMAN RESOURCE MANAGEMENT</li>
                                <li>MARKETING MANAGEMENT</li>
                                <li>FINANCIAL MANAGEMENT*</li>
                                <li>FASHION DESIGNING</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>PG</td>
                        <td>MASTER OF COMMECE</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>JOURNALISM AND MASS COMMUNICATION</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>POLITICAL SCIENCES</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>MATHEMATICS</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>SOCIOLOGY</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>HISTORY</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>HINDI</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>ECONOMICS</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>ENGLISH</td>
                    </tr>
                </table>
                <p>* Fashiod Design will be applicable in the combination with one of the specilization from HR,
                    Marketing and Finance </p><br>
                <p>* Minimum Age of a learner for eligibility for a perticular course of progrmme should be 17 Years in
                    the month of December for respective Academic Session.</p>
                <p>* Maximum duration refers to the Maximum time allowed for the completion of that course. If the
                    student fails to complete it within</p>
                <p>this period full fees will have to be paid again.</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="sidebar-widget">
                <div class="single-sidebar-widget">
                    <div class="tution-wrapper">
                        <div class="tution-fee">
                            <h1>Quick Links</h1>
                        </div>
                        <style>
                            .sub-menu li {
                                padding: 10px;
                                width: 100%;
                                border-bottom: solid 1px #ccc;
                                margin: 0;
                            }
                        </style>
                        <ul class="sub-menu">
                            <li><a routerLink="/all-courses"><span>All Courses</span></a></li>
                            <li><a routerLink="/diploma"><span>Diploma Courses</span></a></li>
                            <li><a routerLink="/advanced-diploma"><span>Advanced Diploma</span></a></li>
                            <li><a routerLink="/degree-courses"><span>Degree Courses</span></a></li>
                            <li><a routerLink="/pg-courses"><span>PG Courses</span></a></li>
                            <li><a routerLink="/pg-diploma-courses"><span>PG Diploma Courses</span></a></li>
                            <li><a routerLink="/new-courses"><span>New Courses</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
