<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>UGC COMPLIANCES</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>UGC COMPLIANCES</h3>
                        <p><a href="https://subhartidde.com/documents/Report_A2021-2022.pdf" target="_blank">1. Application for Recognition (2021-22 and Onwards)</a></p>
                        <p><a href="https://subhartidde.com/documents/UGC_Portal_31.05.2022.pdf" target="_blank">2. Application for Recognition (2022-23 and Onwards)</a></p>
                        <p><a href="https://subhartidde.com/documents/Affidavit_Part_1.pdf" target="_blank">3. Declaration from Registrar</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
