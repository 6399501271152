import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss']
})
export class MfaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
