<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Products</li>
            </ul>
            <h2>Shop</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="odemy-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <p>We found <span class="count">9</span> products available for you</p>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <select>
                                <option>Default</option>
                                <option>Popularity</option>
                                <option>Latest</option>
                                <option>Price: low to high</option>
                                <option>Price: high to low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products/img1.jpg" class="main-image" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/single-products">Note Book Mockup</a></h3>
                                <div class="price">
                                    <span class="old-price">$321</span>
                                    <span class="new-price">$250</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products/img2.jpg" class="main-image" alt="image">
                                </a>
                                <div class="sale-tag">Sale!</div>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/single-products">Motivational Book Cover</a></h3>
                                <div class="price">
                                    <span class="old-price">$210</span>
                                    <span class="new-price">$200</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products/img3.jpg" class="main-image" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/single-products">Book Cover Softcover</a></h3>
                                <div class="price">
                                    <span class="old-price">$210</span>
                                    <span class="new-price">$200</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products/img4.jpg" class="main-image" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/single-products">Stop and Take a Second</a></h3>
                                <div class="price">
                                    <span class="new-price">$150</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products/img5.jpg" class="main-image" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/single-products">Real Life Fairytale</a></h3>
                                <div class="price">
                                    <span class="new-price">$240</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products/img6.jpg" class="main-image" alt="image">
                                </a>
                                <div class="new-tag">New!</div>
                            </div>
                            <div class="products-content">
                                <h3><a routerLink="/single-products">Running From Me</a></h3>
                                <div class="price">
                                    <span class="old-price">$150</span>
                                    <span class="new-price">$100</span>
                                </div>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/products-list-2" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/products-list-2" class="page-numbers">2</a>
                            <a routerLink="/products-list-2" class="page-numbers">3</a>
                            <a routerLink="/products-list-2" class="page-numbers">4</a>
                            <a routerLink="/products-list-2" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <h3 class="widget-title">Search</h3>
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </section>
                    <section class="widget widget_popular_products">
                        <h3 class="widget-title">Popular Products</h3>
                        <article class="item">
                            <a routerLink="/single-products" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-products">Random Romance Novel Title Generator</a></h4>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-products" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-products">Writing Exercises Story Title Ideas</a></h4>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-products" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-products">Amaze Story Kitt Net's Book Ideas</a></h4>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/products-list-1">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/products-list-1">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/products-list-1">Digital <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/products-list-1">SEO <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/products-list-1">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/products-list-1">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/products-list-1">Software <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/products-list-1">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/products-list-1">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/products-list-1">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/products-list-1">Tips <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/products-list-1">Website <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</div>
