<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>ADVANCE DIPLOMA COURSES</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>ADVANCE DIPLOMA COURSES</h3>
                        <div style="overflow-x:auto;">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Title of Programme</th>
                                    <th>Eligibility</th>
                                    <th>Min Course Duration</th>
                                    <th>Max Course Duration</th>
                                    <th>Annual Fees Per Year</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>
                                        ADBA
                                    </td>
                                    <td>10+2 or eq.</td>
                                    <td>2 Year</td>
                                    <td>5 Years</td>
                                    <td>7200</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>ADCA</td>
                                    <td>10+2 or eq.</td>
                                    <td>2 Year</td>
                                    <td>5 Year</td>
                                    <td>8500</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>* Maximum duration refers to the Maximum time allowed for the completion of that course. If
                            the student fails to complete it within this period full fees will have to be paid
                            again.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
