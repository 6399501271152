<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>POST GRADUATE COURSES</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>POST GRADUATE COURSES</h3>
                        <div style="overflow-x:auto;">
                            <table style="border: 1px solid black;">
                                <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Title of Programme</th>
                                    <th>Eligibility</th>
                                    <th>Min Course Duration</th>
                                    <th>Max Course Duration</th>
                                    <th>Annual Fees Per Year</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>
                                        M.A (Education)
                                    </td>
                                    <td>Graduation or eq.</td>
                                    <td>2 Year</td>
                                    <td>5 Years</td>
                                    <td>14400</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>M.A (English, Sociology, Hindi, Political Science, Public Administration,
                                        History)
                                    </td>
                                    <td>Graduation or eq.</td>
                                    <td>2 Year</td>
                                    <td>5 Year</td>
                                    <td>12000</td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>I. M.B.A. with Dual Specialization (Information Technology/ Human Resource
                                        Management/ Marketing Management/ Production & Operation Management/ Financial
                                        Management)
                                        II. M.B.A. with Single Specialization (Hotel & Tourism Mgt/Telecom
                                        Management/Fashion/Pharmaceutical Marketing/ Hospital Administration)
                                    </td>
                                    <td>Graduation or eq.</td>
                                    <td>2 Years
                                        including
                                        Internship
                                    </td>
                                    <td>5 Year</td>
                                    <td>24000</td>
                                </tr>
                                <tr>
                                    <td>4.</td>
                                    <td>
                                        M.A. – JMC
                                    </td>
                                    <td>
                                        Graduation or eq.
                                    </td>
                                    <td>2 Year</td>
                                    <td>5 Year</td>
                                    <td>14000</td>
                                </tr>
                                <tr>
                                    <td>5.</td>
                                    <td>M. Lib</td>
                                    <td>B.Lib or eq.</td>
                                    <td>1 Year</td>
                                    <td>3 Year</td>
                                    <td>18000</td>
                                </tr>
                                <tr>
                                    <td>6.</td>
                                    <td>M.Sc Dietetics & Food Service Management</td>
                                    <td>B.Sc Food and Nutrition or eq.</td>
                                    <td>2 Year</td>
                                    <td>5 Year</td>
                                    <td>12500</td>
                                </tr>
                                <tr>
                                    <td>7.</td>
                                    <td>M.Sc (Computer Application)</td>
                                    <td>Graduation or eq.</td>
                                    <td>2 Year</td>
                                    <td>5 Year</td>
                                    <td>
                                        13500
                                    </td>
                                </tr>
                                <tr>
                                    <td>8.</td>
                                    <td>MCA</td>
                                    <td>Graduation with Mathematics or eq.(The students who have not pursued Mathematics
                                        as a distinct subject, are required to pass the quallifying exam)
                                    </td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>13500</td>
                                </tr>
                                <tr>
                                    <td>9.</td>
                                    <td>
                                        M.Com.
                                    </td>
                                    <td>Graduation with Commerce or eq.</td>
                                    <td>2 Year</td>
                                    <td>5 Year</td>
                                    <td>12000</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>* Maximum duration refers to the Maximum time allowed for the completion of that course. If
                            the student fails to complete it within this period full fees will have to be paid
                            again.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
