import { Component, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-coaching-program',
  templateUrl: './coaching-program.component.html',
  styleUrls: ['./coaching-program.component.scss']
})
export class CoachingProgramComponent implements OnInit {

  constructor() { }
    kitchenFeedbackSlides: OwlOptions = {
        items: 1,
        loop: true,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ]
    }

  ngOnInit(): void {
  }

}
