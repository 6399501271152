<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact Us</li>
            </ul>
            <h2>Contact</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <!--<div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    &lt;!&ndash;  <h2>Get in Touch</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>&ndash;&gt;
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>2750 Quadra Street Victoria Road, New York, USA</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+44457895789">(+44) - 45789 - 5789</a></p>
                            <p>Mail: <a href="mailto:hello@odemy.com">hello@odemy.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09:00 - 20:00</p>
                            <p>Sunday & Saturday: 10:30 - 22:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text"
                                           #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is
                                            minimum {{ name.errors.minlength.requiredLength }} character.
                                        </div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel"
                                           class="form-control" id="email" placeholder="Your email address">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address
                                        is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel"
                                           class="form-control" id="number" placeholder="Your phone number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number
                                        is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30"
                                              rows="5" class="form-control"
                                              placeholder="Write your message..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is
                                        required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Send
                                    Message<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>-->
            <div class="row">
                <div class="col-md-6">
                    <h4 class="contact-title">contact info</h4>
                    <div class="postal_address">

                        <ul>
                            <li>
                                <div class="desc">
                                    <p>
                                        Subhartipuram, NH-58, Delhi-Haridwar, Meerut Bypass Rd, Meerut, Uttar Pradesh
                                        250005
                                    </p>
                                </div>
                            </li>
                            <li>
                                <h4 class="contact-title" style="margin-top:30px">FOR ENQUIRY/GRIEVANCE :</h4>

                                <div class="desc">

                                    <p>(+91) 0121 6675091</p>

                                    <h4 class="contact-title" style="margin-top:30px">FOR ADMISSIONS:</h4>

                                    <p> (+91) 0121-6675092</p>


                                    <!-- <h4 class="contact-title" style="margin-top:30px">social media</h4>
                                     <div class="link-social">
                                         <a href="https://www.facebook.com/subhartiuni" target="_blank"><i
                                             class="zmdi zmdi-facebook"></i></a>
                                         <a href="https://twitter.com/subhartiofficia" target="_blank"><i
                                             class="zmdi zmdi-twitter"></i></a>
                                         <a href="https://www.youtube.com/channel/UCg9Upto51X-DRkibtkRKryQ"
                                            target="_blank"><i class="zmdi zmdi-youtube"></i></a>
                                         <a href="https://instagram.com/subhartiuni.official" target="_blank"><i
                                             class="zmdi zmdi-instagram"></i></a>
                                     </div>-->
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>

                <div class="col-md-6">
                    <div class="postal_address">

                        <ul>

                            <li>
                                <h4 class="contact-title" style="margin-top:30px">
                                    For Distance Enquiry</h4>
                                <ul>
                                    <li>
                                        <div class="desc">
                                            <p><a routerLink="/contact"
                                                  style="font-size:20px; font-weight:800; color:#86bc42"> Click Here</a>
                                            </p>
                                            <!--<p>

                                               <strong>For Verification Email at</strong>
                                                <br>
                                                <a href="mailto:examinations@subhartidde.co.in">examinations@subhartidde.co.in</a></p>

                                            <p>
                                                <strong>For Student Support Email at</strong>
                                                <br>
                                                <a href="mailto:helpdesk@subhartidde.co.in">helpdesk@subhartidde.co.in</a></p>
                                                <p>
                                                <strong>For Enquiry/Grievance Email at</strong>
                                                <br>
                                                <a href="mailto:grievance@subhartidde.co.in">grievance@subhartidde.co.in</a></p>-->
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <h4 class="contact-title" style="margin-top:30px">
                                    Website :</h4>
                                <span class="imgbox"></span>
                                <div class="desc">
                                    <p>
                                        <b>www.subhartidde.com</b><br>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1745.5050316225033!2d77.636688!3d28.957426!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xddc6f062b674f2f!2sDirectorate%20Of%20Distance%20Education%2C%20Swami%20Vivekanand%20Subharti%20University!5e0!3m2!1sen!2sin!4v1627441455602!5m2!1sen!2sin"
        loading="lazy"></iframe>
