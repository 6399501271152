<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                 <li><a routerLink="/">Home</a></li>
                 <li>Contact</li>
             </ul>-->
            <h2>Feedback</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <!--<div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    &lt;!&ndash;  <h2>Get in Touch</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>&ndash;&gt;
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>2750 Quadra Street Victoria Road, New York, USA</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+44457895789">(+44) - 45789 - 5789</a></p>
                            <p>Mail: <a href="mailto:hello@odemy.com">hello@odemy.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09:00 - 20:00</p>
                            <p>Sunday & Saturday: 10:30 - 22:00</p>
                        </li>
                    </ul>
                </div>-->
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <h2>Feedback Form</h2>
                    <!--<p>Your email address will not be published. Required fields are marked *</p>-->
                    <!-- <form>
                         <div class="row">
                             <div class="col-lg-12 col-md-6">
                                 <div class="form-group">
                                     <input required minlength="3" maxlength="50" ngModel name="name" type="text"
                                            class="form-control" id="name" placeholder="Your name">
                                     <div class="alert alert-danger">
                                         <div>Name is required.</div>
                                         <div>Name is
                                             minimum character.
                                         </div>
                                         <div>Name is maximum 50 character.</div>
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-6">
                                 <div class="form-group">
                                     <input required ngModel name="email" type="text" #email="ngModel"
                                            class="form-control" id="email" placeholder="Your email address">
                                     <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address
                                         is required.
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-12">
                                 <div class="form-group">
                                     <input required ngModel name="number" type="text" #number="ngModel"
                                            class="form-control" id="number" placeholder="Your phone number">
                                     <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number
                                         is required.
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-12">
                                 <div class="form-group">
                                     <textarea required ngModel #message="ngModel" name="message" id="message" cols="30"
                                               rows="5" class="form-control"
                                               placeholder="Write your message..."></textarea>
                                     <div class="alert alert-danger">Message is
                                         required.
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-12">
                                 <button type="submit" class="default-btn">Send
                                     Message<span></span></button>
                             </div>
                         </div>
                     </form>-->
                    <div class="container mt-3">
                        <!--<h2>Stacked form</h2>-->
                        <form action="/action_page.php">
                            <div class="mb-3 mt-3">
                                <label>Name:</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter Name"
                                       name="text">
                            </div>
                            <div class="mb-3 mt-3">
                                <label>Father/Husband's Name:</label>
                                <input type="text" class="form-control" id="" placeholder="Father/Husband's Name"
                                       name="text">
                            </div>
                            <div class="mb-3 mt-3">
                                <label>Course:</label>
                                <input type="text" class="form-control" id="" placeholder="Course"
                                       name="text">
                            </div>
                            <div class="mb-3 mt-3">
                                <label for="email">Email:</label>
                                <input type="email" class="form-control" id="email" placeholder="Enter email"
                                       name="email">
                            </div>
                            <div class="mb-3">
                                <label for="pwd">Number:</label>
                                <input type="number" class="form-control" id="pwd" placeholder="Number"
                                       name="pswd">
                            </div>
                            <div class="mb-3">
                                <label for="comment">Comments:</label>
                                <textarea class="form-control" rows="5" id="comment" name="text"></textarea>
                            </div>
                            <button type="submit" class="btn btn-success text-center">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
            </div>
        </div>
    </div>
</div>

