<div class="container">
    <div class="section-title">
        <span class="sub-title">News and Blogs</span>
        <h2>Our Latest Publications</h2>
        <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/img1.jpg" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-1" class="category">Education</a>
                    <h3><a routerLink="/single-blog-1">University Admissions Could Face Emergency Controls</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <span>Alex Morgan</span>
                            </div>
                        </li>
                        <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/img2.jpg" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-1" class="category">Online</a>
                    <h3><a routerLink="/single-blog-1">Online Learning Can Prepare Students For A Fast-Changing</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                <span>Sarah Taylor</span>
                            </div>
                        </li>
                        <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/img3.jpg" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-1" class="category">Learning</a>
                    <h3><a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                <span>David Warner</span>
                            </div>
                        </li>
                        <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="blog-post-info">
                <p>Get into details now?​ <a routerLink="/blog-1">View all posts</a></p>
            </div>
        </div>
    </div>
</div>