<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Faculty Details</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>Faculty Details</h3>
                        <p><a href="https://subhartidde.com/documents/Faculty_List.pdf" target="_blank">1. ODL Faculty</a></p>
                        <p><a href="https://subhartidde.com/documents/OL_FACULTIES.pdf" target="_blank">2. OL Faculty</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
