<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>DEGREE COURSES</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>DEGREE COURSES</h3>
                        <p>1: Diploma Programmes</p>
                        <div style="overflow-x:auto;">
                            <table style="border: 1px solid black;">
                                <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Title of Programme</th>
                                    <th>Eligibility</th>
                                    <th>Min Course Duration</th>
                                    <th>Max Course Duration</th>
                                    <th>Annual Fees Per Year</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>
                                        B.A.(Hindi,English,Mathematics,Economics,Sociology,Political Science,History)
                                    </td>
                                    <td>10+2 or eq.</td>
                                    <td>3 Year</td>
                                    <td>6 Years</td>
                                    <td>8000</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>B.A (Fashion Designing & Fine Arts)</td>
                                    <td>10+2 or eq.</td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>7200</td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>BBA</td>
                                    <td>10+2 or eq.</td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>12000</td>
                                </tr>
                                <tr>
                                    <td>4.</td>
                                    <td>B.A. – JMC</td>
                                    <td>10+2 or eq.</td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>12000</td>
                                </tr>
                                <tr>
                                    <td>5.</td>
                                    <td>B. Lib</td>
                                    <td>Graduation or eq.</td>
                                    <td>1 Year</td>
                                    <td>3 Year</td>
                                    <td>14000</td>
                                </tr>
                                <tr>
                                    <td>6.</td>
                                    <td>B.Sc (PCM)</td>
                                    <td>10+2 with PCM</td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>8500</td>
                                </tr>
                                <tr>
                                    <td>7.</td>
                                    <td>B.Sc (ZBC)</td>
                                    <td>10+2 with PCB</td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>8500</td>
                                </tr>
                                <tr>
                                    <td>8.</td>
                                    <td>BCA</td>
                                    <td>10+2 or eq.</td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>10000</td>
                                </tr>
                                <tr>
                                    <td>9.</td>
                                    <td>B.Com.</td>
                                    <td>10+2 or eq.</td>
                                    <td>3 Year</td>
                                    <td>6 Year</td>
                                    <td>10000</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>* Maximum duration refers to the Maximum time allowed for the completion of that course. If
                            the student fails to complete it within this period full fees will have to be paid
                            again.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
