<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo_v02.png" alt="logo" style="height: 47px"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!--<form class="search-box">
                    <input type="text" class="input-search" placeholder="Search for anything">
                    <button type="submit"><i class="flaticon-search"></i></button>
                </form>-->
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">About Us <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/dde" class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">DDE</a></li>
                            <li class="nav-item"><a routerLink="/university" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">University</a>
                            </li>
                            <li class="nav-item"><a routerLink="/director-messege" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Director's
                                Messege</a></li>
                            <li class="nav-item"><a href="https://subhartidde.com/documents/Resolution-for-DDE.pdf"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Resolution
                                For DDE</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Recognitions <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="https://subhartidde.com/documents/2f.pdf"
                                                            target="_blank"
                                                            class="nav-link" routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">Section 2(F)</a>
                                    </li>
                                    <li class="nav-item"><a
                                        href="https://subhartidde.com/documents/Teritorial_Juricdistion_1.pdf"
                                        target="_blank" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Teritorial
                                        Juricdistion</a></li>
                                    <li class="nav-item"><a routerLink="/recognition" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">ODL Model</a></li>
                                    <li class="nav-item"><a
                                        href="https://subhartidde.com/documents/UGC_Univ_%20Approval.pdf"
                                        target="_blank" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Regular Mode</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Act And Statutes <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="https://subhartidde.com/documents/UmbrellaAct.pdf"
                                                            target="_blank"
                                                            class="nav-link" routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">DEB Compliance</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item"><a
                                href="https://www.ugc.ac.in/pdfnews/FINAL%20RECOGNITION%20STATUS%2008-05-2019.pdf"
                                target="_blank" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Details
                                On DEB Website</a></li>
                            <li class="nav-item"><a routerLink="/compliance" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">DEB
                                Compliance</a></li>
                            <li class="nav-item"><a routerLink="/vision-mission" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Vision
                                & Mission </a></li>
                            <li class="nav-item"><a routerLink="/world-of-subharti" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">World
                                Of Subharti </a></li>
                            <li class="nav-item"><a href="https://subhartidde.com/documents/OL_FACULTIES.pdf"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Faculty
                                Details </a></li>
                            <li class="nav-item"><a
                                href="https://subhartidde.com/documents/Administrative_staff_DDE.pdf" target="_blank"
                                class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Administrative
                                Staff </a></li>
                            <li class="nav-item"><a href="https://subhartidde.com/documents/Infrastructure.pdf"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Infrastructure
                            </a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Programmes <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <!--<li class="nav-item"><a href="javascript:void(0)" class="nav-link">All Courses</a>
                               &lt;!&ndash; <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about-1" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">MA (Education)</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-3" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">MSC
                                        (Mathematics)</a>
                                    </li>
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">MBA <i
                                        class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/vendor-certification-etraining"
                                                                    class="nav-link" routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}">MBA
                                                (Retall Management)</a>
                                            </li>
                                            <li class="nav-item"><a routerLink="/vendor-certification-etraining"
                                                                    class="nav-link" routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}">MBA
                                                (Banking)</a>
                                            </li>
                                            <li class="nav-item"><a routerLink="/vendor-certification-etraining"
                                                                    class="nav-link" routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}">MBA (Rural
                                                Development)</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">MA<i
                                        class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                    routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}">MA
                                                (Mathematics)</a>
                                            </li>
                                            <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                    routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}">MA
                                                (Economics)</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">BA</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">BFA</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">MFA</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">BPA</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">MPA</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">BCOM (Hons.)</a>
                                    </li>
                                </ul>&ndash;&gt;
                            </li>-->
                            <li class="nav-item"><a routerLink="/all-courses" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">All
                                Courses
                            </a></li>
                            <!--<li class="nav-item"><a href="javascript:void(0)" class="nav-link">Diploma Courses <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about-1" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 01</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-2" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 02</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-3" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 03</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 04</a>
                                    </li>
                                </ul>
                            </li>-->
                            <!-- <li class="nav-item"><a routerLink="/diploma" class="nav-link"
                                                     routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Diploma
                                 Courses</a></li>
                             <li class="nav-item"><a routerLink="/advanced-diploma" class="nav-link"
                                                     routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Advanced
                                 Diploma</a></li>
                             <li class="nav-item"><a routerLink="/degree-courses" class="nav-link"
                                                     routerLinkActive="active"
                                                     [routerLinkActiveOptions]="{exact: true}">Degree Courses</a></li>
                             <li class="nav-item"><a routerLink="/pg-courses" class="nav-link" routerLinkActive="active"
                                                     [routerLinkActiveOptions]="{exact: true}">PG Courses</a></li>
                             <li class="nav-item"><a routerLink="/pg-diploma-courses" class="nav-link"
                                                     routerLinkActive="active"
                                                     [routerLinkActiveOptions]="{exact: true}">PG Diploma Courses</a>
                             </li>-->
                            <!--  <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                              <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Login/Register</a>
                              </li>
                              <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                              <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                              <li class="nav-item"><a routerLink="/purchase-guide" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Purchase
                                  Guide</a></li>
                              <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                  Policy</a></li>
                              <li class="nav-item"><a routerLink="/terms-of-service" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms
                                  of Service</a></li>-->
                        </ul>
                    </li>
                    <!--<li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Academic <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 01</a>
                                                </li>
                                                <li><a routerLink="/courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 02</a>
                                                </li>
                                                <li><a routerLink="/courses-3" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 03</a>
                                                </li>
                                                <li><a routerLink="/courses-4" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 04</a>
                                                </li>
                                                <li><a routerLink="/courses-5" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses List</a></li>
                                                <li><a routerLink="/courses-6" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Masonry</a>
                                                </li>
                                                <li><a routerLink="/courses-7" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Right
                                                    Sidebar</a></li>
                                            </ul>
                                        </div>
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/single-courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Single Layout 01</a>
                                                </li>
                                                <li><a routerLink="/single-courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Single Layout 02</a>
                                                </li>
                                                <li><a routerLink="/categories" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Categories</a>
                                                </li>
                                                <li><a routerLink="/membership-levels" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Membership Levels</a>
                                                </li>
                                                <li><a routerLink="/become-a-teacher" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Become a Teacher</a>
                                                </li>
                                                <li><a routerLink="/profile" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Profile</a></li>
                                                <li><a routerLink="/profile-quiz" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Profile Quiz</a></li>
                                            </ul>
                                        </div>
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 01</a>
                                                </li>
                                                <li><a routerLink="/courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 02</a>
                                                </li>
                                                <li><a routerLink="/courses-3" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 03</a>
                                                </li>
                                                <li><a routerLink="/courses-4" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Grid 04</a>
                                                </li>
                                                <li><a routerLink="/courses-5" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses List</a></li>
                                                <li><a routerLink="/courses-6" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Masonry</a>
                                                </li>
                                                <li><a routerLink="/courses-7" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Right
                                                    Sidebar</a></li>
                                            </ul>
                                        </div>
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/single-courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Single Layout 01</a>
                                                </li>
                                                <li><a routerLink="/single-courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Single Layout 02</a>
                                                </li>
                                                <li><a routerLink="/categories" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Courses Categories</a>
                                                </li>
                                                <li><a routerLink="/membership-levels" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Membership Levels</a>
                                                </li>
                                                <li><a routerLink="/become-a-teacher" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Become a Teacher</a>
                                                </li>
                                                <li><a routerLink="/profile" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Profile</a></li>
                                                <li><a routerLink="/profile-quiz" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Profile Quiz</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-code-alt'></i>
                                                </div>
                                                <h3>Development</h3>
                                                <span class="sub-title">60 Courses</span>
                                                <a routerLink="/courses-1" class="link-btn"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-camera'></i>
                                                </div>
                                                <h3>Photography</h3>
                                                <span class="sub-title">21 Courses</span>
                                                <a routerLink="/courses-2" class="link-btn"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-layer'></i>
                                                </div>
                                                <h3>Design</h3>
                                                <span class="sub-title">58 Courses</span>
                                                <a routerLink="/courses-3" class="link-btn"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bxs-flag-checkered'></i>
                                                </div>
                                                <h3>Language</h3>
                                                <span class="sub-title">99 Courses</span>
                                                <a routerLink="/courses-4" class="link-btn"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-health'></i>
                                                </div>
                                                <h3>Fitness</h3>
                                                <span class="sub-title">21 Courses</span>
                                                <a routerLink="/courses-5" class="link-btn"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-line-chart'></i>
                                                </div>
                                                <h3>Business</h3>
                                                <span class="sub-title">49 Courses</span>
                                                <a routerLink="/courses-6" class="link-btn"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>-->
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Academic <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/admission-procedure" class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Admission Procedure</a>
                            </li>
                            <li class="nav-item"><a routerLink="/credit-transfer-policy" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Credit
                                Transfer Policy</a>
                            </li>
                            <li class="nav-item"><a routerLink="/latest-entry-policy" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Latest
                                Entry Policy</a></li>
                            <!--<li class="nav-item"><a href="javascript:void(0)" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                Fee Structure</a></li>-->
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Fee Structure <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/for-session-2021-22"
                                                            class="nav-link" routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">For Session
                                        2021-22</a>
                                    </li>
                                    <li class="nav-item"><a
                                        routerLink="/for-session-2021-23"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">For Session 2022-23</a></li>
                                    <!--<li class="nav-item"><a routerLink="/distance-learning" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">ODL Model</a></li>-->
                                    <!-- <li class="nav-item"><a
                                         href="https://subhartidde.com/documents/UGC_Univ_%20Approval.pdf"
                                         target="_blank" class="nav-link"
                                         routerLinkActive="active"
                                         [routerLinkActiveOptions]="{exact: true}">Regular Mode</a>
                                     </li>-->
                                </ul>
                            </li>
                            <li class="nav-item"><a href="https://subhartidde.com/documents/FeeRefundPolicy.pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Refund
                                Policy</a></li>
                            <li class="nav-item"><a routerLink="/semester-syllabus" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Semester
                                Syllabus 2021</a></li>
                            <li class="nav-item"><a routerLink="/detailed-syllabus" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Detailed
                                Syllabus</a></li>
                            <li class="nav-item"><a routerLink="/assignments" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Assignments </a>
                            </li>
                            <li class="nav-item"><a routerLink="/guidelines" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Guidelines </a>
                            </li>
                            <li class="nav-item"><a routerLink="/academic-calender" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Academic
                                Calender </a></li>
                            <li class="nav-item"><a routerLink="/admission-details" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Academic
                                Details </a></li>
                            <li class="nav-item"><a routerLink="/course-slm" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Course
                                SLM's
                            </a></li>
                            <li class="nav-item"><a href="https://subhartidde.com/documents/SLM.pdf" target="_blank"
                                                    class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">SLM
                                Details
                            </a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Examinations <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/examination-schedule" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Examination
                                Schedule</a></li>
                            <li class="nav-item"><a routerLink="/evalution-procedure" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Evalution
                                Procedure</a></li>
                            <li class="nav-item"><a href="https://subhartidde.com/documents/ExamCenter-2021-22.pdf"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Examination
                                Centers</a></li>
                            <!--  <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                              <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                              <li class="nav-item"><a routerLink="/single-products" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Product
                                  Details</a></li>-->
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Student Support <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/downloads" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Downloads</a></li>
                            <li class="nav-item"><a routerLink="/course-co-ordinators" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Course
                                Co-Ordinators</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Enquiry/Grievance
                                Form</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Results <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="http://182.74.167.41/studentlogin.aspx" target="_blank"
                                                    class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Student
                                Login</a></li>
                            <li class="nav-item"><a href="http://182.74.167.61:444/Account/StudentLogin.aspx"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Student
                                Login(C 22/A 22-23)</a></li>
                            <!--  <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                              <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                              <li class="nav-item"><a routerLink="/single-products" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Product
                                  Details</a></li>-->
                        </ul>
                    </li>
                    <!--<li class="nav-item"><a href="javascript:void(0)" class="nav-link">Latest News <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Grid (2 in Row)</a></li>
                            <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Grid (3 in Row)</a></li>
                            <li class="nav-item"><a routerLink="/blog-3" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Grid (Full Width)</a></li>
                            <li class="nav-item"><a routerLink="/blog-4" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Right Sidebar</a></li>
                            <li class="nav-item"><a routerLink="/blog-5" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Masonry (3 in Row)</a>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Single Post <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/single-blog-1" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">Default</a></li>
                                    <li class="nav-item"><a routerLink="/single-blog-2" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">With Video</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/single-blog-3" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">With Image
                                        Slider</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>-->
                    <!--<li class="nav-item"><a routerLink="/latest-news" class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Latest News</a></li>-->
                </ul>
                <ul class="navbar-nav for-responsive">
                    <!--<li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">eLearning
                                School</a></li>
                            <li class="nav-item"><a routerLink="/language-school" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Language School</a></li>
                            <li class="nav-item"><a routerLink="/yoga-training" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Yoga Training</a></li>
                            <li class="nav-item"><a routerLink="/health-coaching" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Health Coaching</a></li>
                            <li class="nav-item"><a routerLink="/kindergarten" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Kindergarten</a></li>
                            <li class="nav-item"><a routerLink="/gym-coaching" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Gym Coaching</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Popular Demos <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/vendor-certification-etraining"
                                                            class="nav-link" routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">Vendor Certification eTraining</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/online-training-school" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">Online Training School</a></li>
                                    <li class="nav-item"><a routerLink="/distance-learning" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">Distance Learning</a></li>
                                    <li class="nav-item"><a routerLink="/modern-schooling" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">Modern Schooling</a></li>
                                    <li class="nav-item"><a routerLink="/learning-management" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">Learning Management</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/business-coaching" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Business Coaching <span
                                class="new">New</span></a></li>
                            <li class="nav-item"><a routerLink="/motivational-coaching" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Motivational Coaching <span
                                class="new">New</span></a></li>
                            <li class="nav-item"><a routerLink="/kitchen-coaching" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Kitchen Coaching <span
                                class="new">New</span></a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pages <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">About Us Us <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about-1" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">About Us Us 01</a></li>
                                    <li class="nav-item"><a routerLink="/about-2" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">About Us Us 02</a></li>
                                    <li class="nav-item"><a routerLink="/about-3" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">About Us Us 03</a></li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">About Us Us 04</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/success-story" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Success Story</a></li>
                            <li class="nav-item"><a routerLink="/teacher" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Teacher</a>
                            </li>
                            <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Gallery</a>
                            </li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">FAQ</a>
                            </li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Contact
                                Us</a></li>
                            <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Login/Register</a></li>
                            <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">404
                                Error Page</a></li>
                            <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Coming
                                Soon</a></li>
                            <li class="nav-item"><a routerLink="/purchase-guide" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Purchase Guide</a></li>
                            <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Privacy Policy</a></li>
                            <li class="nav-item"><a routerLink="/terms-of-service" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Terms of Service</a></li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Courses <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 01</a></li>
                                                <li><a routerLink="/courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 02</a></li>
                                                <li><a routerLink="/courses-3" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 03</a></li>
                                                <li><a routerLink="/courses-4" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 04</a></li>
                                                <li><a routerLink="/courses-5" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses List</a></li>
                                                <li><a routerLink="/courses-6" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Masonry</a></li>
                                                <li><a routerLink="/courses-7" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Right Sidebar</a></li>
                                            </ul>
                                        </div>
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/single-courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Single Layout 01</a></li>
                                                <li><a routerLink="/single-courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Single Layout 02</a></li>
                                                <li><a routerLink="/categories" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Categories</a></li>
                                                <li><a routerLink="/membership-levels" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Membership Levels</a></li>
                                                <li><a routerLink="/become-a-teacher" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Become a Teacher</a></li>
                                                <li><a routerLink="/profile" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Profile</a></li>
                                                <li><a routerLink="/profile-quiz" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Profile Quiz</a></li>
                                            </ul>
                                        </div>
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 01</a></li>
                                                <li><a routerLink="/courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 02</a></li>
                                                <li><a routerLink="/courses-3" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 03</a></li>
                                                <li><a routerLink="/courses-4" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Grid 04</a></li>
                                                <li><a routerLink="/courses-5" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses List</a></li>
                                                <li><a routerLink="/courses-6" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Masonry</a></li>
                                                <li><a routerLink="/courses-7" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Right Sidebar</a></li>
                                            </ul>
                                        </div>
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/single-courses-1" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Single Layout 01</a></li>
                                                <li><a routerLink="/single-courses-2" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Single Layout 02</a></li>
                                                <li><a routerLink="/categories" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Courses Categories</a></li>
                                                <li><a routerLink="/membership-levels" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Membership Levels</a></li>
                                                <li><a routerLink="/become-a-teacher" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Become a Teacher</a></li>
                                                <li><a routerLink="/profile" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Profile</a></li>
                                                <li><a routerLink="/profile-quiz" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}"
                                                       (click)="toggleClass()">Profile Quiz</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-code-alt'></i>
                                                </div>
                                                <h3>Development</h3>
                                                <span class="sub-title">60 Courses</span>
                                                <a routerLink="/courses-1" class="link-btn" (click)="toggleClass()"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-camera'></i>
                                                </div>
                                                <h3>Photography</h3>
                                                <span class="sub-title">21 Courses</span>
                                                <a routerLink="/courses-2" class="link-btn" (click)="toggleClass()"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-layer'></i>
                                                </div>
                                                <h3>Design</h3>
                                                <span class="sub-title">58 Courses</span>
                                                <a routerLink="/courses-3" class="link-btn" (click)="toggleClass()"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bxs-flag-checkered'></i>
                                                </div>
                                                <h3>Language</h3>
                                                <span class="sub-title">99 Courses</span>
                                                <a routerLink="/courses-4" class="link-btn" (click)="toggleClass()"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-health'></i>
                                                </div>
                                                <h3>Fitness</h3>
                                                <span class="sub-title">21 Courses</span>
                                                <a routerLink="/courses-5" class="link-btn" (click)="toggleClass()"></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                            <div class="single-category-widget">
                                                <div class="icon">
                                                    <i class='bx bx-line-chart'></i>
                                                </div>
                                                <h3>Business</h3>
                                                <span class="sub-title">49 Courses</span>
                                                <a routerLink="/courses-6" class="link-btn" (click)="toggleClass()"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Events <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Events</a>
                            </li>
                            <li class="nav-item"><a routerLink="/single-events" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Events Details</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/products-list-1" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Product List 01</a></li>
                            <li class="nav-item"><a routerLink="/products-list-2" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Product List 02</a></li>
                            <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Cart</a>
                            </li>
                            <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Checkout</a>
                            </li>
                            <li class="nav-item"><a routerLink="/single-products" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">Product Details</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Grid
                                (2 in Row)</a></li>
                            <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Grid
                                (3 in Row)</a></li>
                            <li class="nav-item"><a routerLink="/blog-3" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Grid
                                (Full Width)</a></li>
                            <li class="nav-item"><a routerLink="/blog-4" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Right
                                Sidebar</a></li>
                            <li class="nav-item"><a routerLink="/blog-5" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Masonry
                                (3 in Row)</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Single Post <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/single-blog-1" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">Default</a></li>
                                    <li class="nav-item"><a routerLink="/single-blog-2" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">With Video</a></li>
                                    <li class="nav-item"><a routerLink="/single-blog-3" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}"
                                                            (click)="toggleClass()">With Image Slider</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>-->
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">About Us <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class=""><a routerLink="/dde" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">DDE</a>
                                                </li>
                                                <li class=""><a routerLink="/university" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">University</a>
                                                </li>
                                                <li class=""><a routerLink="/director-messege" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">Director's
                                                    Messege</a></li>
                                                <li class=""><a
                                                    href="https://subhartidde.com/documents/Resolution-for-DDE.pdf"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Resolution
                                                    For DDE</a></li>
                                                <li class="nav-item megamenu"><a href="javascript:void(0)"
                                                                                 class="nav-link">Recognitions <i
                                                    class='bx bx-chevron-right'></i></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <ul class="megamenu-submenu">
                                                                            <li class=""><a
                                                                                href="https://subhartidde.com/documents/2f.pdf"
                                                                                target="_blank"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Section
                                                                                2(F)</a>
                                                                            </li>
                                                                            <li class=""><a
                                                                                href="https://subhartidde.com/documents/Teritorial_Juricdistion_1.pdf"
                                                                                target="_blank" class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Teritorial
                                                                                Juricdistion</a></li>
                                                                            <li class=""><a
                                                                                routerLink="/distance-learning"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">ODL
                                                                                Model</a></li>
                                                                            <li class=""><a
                                                                                href="https://subhartidde.com/documents/UGC_Univ_%20Approval.pdf"
                                                                                target="_blank" class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Regular
                                                                                Mode</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="nav-item megamenu"><a href="javascript:void(0)"
                                                                                 class="nav-link">Act And Statutes <i
                                                    class='bx bx-chevron-right'></i></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <ul class="megamenu-submenu">
                                                                            <li class=""><a
                                                                                href="https://subhartidde.com/documents/UmbrellaAct.pdf"
                                                                                target="_blank"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">DEB
                                                                                Compliance</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class=""><a
                                                    href="https://www.ugc.ac.in/pdfnews/FINAL%20RECOGNITION%20STATUS%2008-05-2019.pdf"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Details
                                                    On DEB Website</a></li>
                                                <li class=""><a routerLink="/compliance" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">DEB
                                                    Compliance</a></li>
                                                <li class=""><a routerLink="/vision-mission" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">Vision
                                                    & Mission </a></li>
                                                <li class=""><a routerLink="/world-of-subharti" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">World
                                                    Of Subharti </a></li>
                                                <li class=""><a routerLink="/kitchen-coaching" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">Faculty
                                                    Details </a></li>
                                                <li class=""><a
                                                    href="https://subhartidde.com/documents/Administrative_staff_DDE.pdf"
                                                    target="_blank"
                                                    class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Administrative
                                                    Staff </a></li>
                                                <li class=""><a routerLink="/kitchen-coaching" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">Infrastructure
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Programmes <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class=""><a routerLink="all-courses">All
                                                    Courses</a>
                                                    <!-- <ul class="dropdown-menu">
                                                         <li class="nav-item"><a routerLink="/about-1" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">MA
                                                             (Education)</a>
                                                         </li>
                                                         <li class="nav-item"><a routerLink="/about-3" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">MSC
                                                             (Mathematics)</a>
                                                         </li>
                                                         <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">MBA
                                                             <i
                                                                 class='bx bx-chevron-right'></i></a>
                                                             <ul class="dropdown-menu">
                                                                 <li class="nav-item"><a routerLink="/vendor-certification-etraining"
                                                                                         class="nav-link" routerLinkActive="active"
                                                                                         [routerLinkActiveOptions]="{exact: true}">MBA
                                                                     (Retall Management)</a>
                                                                 </li>
                                                                 <li class="nav-item"><a routerLink="/vendor-certification-etraining"
                                                                                         class="nav-link" routerLinkActive="active"
                                                                                         [routerLinkActiveOptions]="{exact: true}">MBA
                                                                     (Banking)</a>
                                                                 </li>
                                                                 <li class="nav-item"><a routerLink="/vendor-certification-etraining"
                                                                                         class="nav-link" routerLinkActive="active"
                                                                                         [routerLinkActiveOptions]="{exact: true}">MBA
                                                                     (Rural
                                                                     Development)</a>
                                                                 </li>
                                                             </ul>
                                                         </li>
                                                         <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">MA<i
                                                             class='bx bx-chevron-right'></i></a>
                                                             <ul class="dropdown-menu">
                                                                 <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                         routerLinkActive="active"
                                                                                         [routerLinkActiveOptions]="{exact: true}">MA
                                                                     (Mathematics)</a>
                                                                 </li>
                                                                 <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                         routerLinkActive="active"
                                                                                         [routerLinkActiveOptions]="{exact: true}">MA
                                                                     (Economics)</a>
                                                                 </li>
                                                             </ul>
                                                         </li>
                                                         <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">BA</a>
                                                         </li>
                                                         <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">BFA</a>
                                                         </li>
                                                         <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">MFA</a>
                                                         </li>
                                                         <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">BPA</a>
                                                         </li>
                                                         <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">MPA</a>
                                                         </li>
                                                         <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                                                 routerLinkActive="active"
                                                                                 [routerLinkActiveOptions]="{exact: true}">BCOM
                                                             (Hons.)</a>
                                                         </li>
                                                     </ul>-->
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!--<li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Diploma Courses <i
                                class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about-1" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 01</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-2" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 02</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-3" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 03</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/about-4" class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{exact: true}">About Us Us 04</a>
                                    </li>
                                </ul>
                            </li>-->

                            <!--  <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                              <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Login/Register</a>
                              </li>
                              <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                              <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                              <li class="nav-item"><a routerLink="/purchase-guide" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Purchase
                                  Guide</a></li>
                              <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                  Policy</a></li>
                              <li class="nav-item"><a routerLink="/terms-of-service" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms
                                  of Service</a></li>-->
                        </ul>
                    </li>
                    <!--  <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Academic <i
                          class='bx bx-chevron-down'></i></a>
                          <ul class="dropdown-menu">
                              <li class="nav-item">
                                  <div class="container">
                                      <div class="row">
                                          <div class="col">
                                              <ul class="megamenu-submenu">
                                                  <li><a routerLink="/courses-1" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      01</a>
                                                  </li>
                                                  <li><a routerLink="/courses-2" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      02</a>
                                                  </li>
                                                  <li><a routerLink="/courses-3" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      03</a>
                                                  </li>
                                                  <li><a routerLink="/courses-4" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      04</a>
                                                  </li>
                                                  <li><a routerLink="/courses-5" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses
                                                      List</a></li>
                                                  <li><a routerLink="/courses-6" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses
                                                      Masonry</a>
                                                  </li>
                                                  <li><a routerLink="/courses-7" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Right
                                                      Sidebar</a></li>
                                              </ul>
                                          </div>
                                          <div class="col">
                                              <ul class="megamenu-submenu">
                                                  <li><a routerLink="/single-courses-1" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Single Layout
                                                      01</a>
                                                  </li>
                                                  <li><a routerLink="/single-courses-2" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Single Layout
                                                      02</a>
                                                  </li>
                                                  <li><a routerLink="/categories" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses
                                                      Categories</a>
                                                  </li>
                                                  <li><a routerLink="/membership-levels" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Membership
                                                      Levels</a>
                                                  </li>
                                                  <li><a routerLink="/become-a-teacher" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Become a
                                                      Teacher</a>
                                                  </li>
                                                  <li><a routerLink="/profile" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Profile</a>
                                                  </li>
                                                  <li><a routerLink="/profile-quiz" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Profile
                                                      Quiz</a></li>
                                              </ul>
                                          </div>
                                          <div class="col">
                                              <ul class="megamenu-submenu">
                                                  <li><a routerLink="/courses-1" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      01</a>
                                                  </li>
                                                  <li><a routerLink="/courses-2" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      02</a>
                                                  </li>
                                                  <li><a routerLink="/courses-3" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      03</a>
                                                  </li>
                                                  <li><a routerLink="/courses-4" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Grid
                                                      04</a>
                                                  </li>
                                                  <li><a routerLink="/courses-5" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses
                                                      List</a></li>
                                                  <li><a routerLink="/courses-6" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses
                                                      Masonry</a>
                                                  </li>
                                                  <li><a routerLink="/courses-7" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses Right
                                                      Sidebar</a></li>
                                              </ul>
                                          </div>
                                          <div class="col">
                                              <ul class="megamenu-submenu">
                                                  <li><a routerLink="/single-courses-1" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Single Layout
                                                      01</a>
                                                  </li>
                                                  <li><a routerLink="/single-courses-2" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Single Layout
                                                      02</a>
                                                  </li>
                                                  <li><a routerLink="/categories" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Courses
                                                      Categories</a>
                                                  </li>
                                                  <li><a routerLink="/membership-levels" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Membership
                                                      Levels</a>
                                                  </li>
                                                  <li><a routerLink="/become-a-teacher" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Become a
                                                      Teacher</a>
                                                  </li>
                                                  <li><a routerLink="/profile" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Profile</a>
                                                  </li>
                                                  <li><a routerLink="/profile-quiz" routerLinkActive="active"
                                                         [routerLinkActiveOptions]="{exact: true}">Profile
                                                      Quiz</a></li>
                                              </ul>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                              <div class="single-category-widget">
                                                  <div class="icon">
                                                      <i class='bx bx-code-alt'></i>
                                                  </div>
                                                  <h3>Development</h3>
                                                  <span class="sub-title">60 Courses</span>
                                                  <a routerLink="/courses-1" class="link-btn"></a>
                                              </div>
                                          </div>
                                          <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                              <div class="single-category-widget">
                                                  <div class="icon">
                                                      <i class='bx bx-camera'></i>
                                                  </div>
                                                  <h3>Photography</h3>
                                                  <span class="sub-title">21 Courses</span>
                                                  <a routerLink="/courses-2" class="link-btn"></a>
                                              </div>
                                          </div>
                                          <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                              <div class="single-category-widget">
                                                  <div class="icon">
                                                      <i class='bx bx-layer'></i>
                                                  </div>
                                                  <h3>Design</h3>
                                                  <span class="sub-title">58 Courses</span>
                                                  <a routerLink="/courses-3" class="link-btn"></a>
                                              </div>
                                          </div>
                                          <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                              <div class="single-category-widget">
                                                  <div class="icon">
                                                      <i class='bx bxs-flag-checkered'></i>
                                                  </div>
                                                  <h3>Language</h3>
                                                  <span class="sub-title">99 Courses</span>
                                                  <a routerLink="/courses-4" class="link-btn"></a>
                                              </div>
                                          </div>
                                          <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                              <div class="single-category-widget">
                                                  <div class="icon">
                                                      <i class='bx bx-health'></i>
                                                  </div>
                                                  <h3>Fitness</h3>
                                                  <span class="sub-title">21 Courses</span>
                                                  <a routerLink="/courses-5" class="link-btn"></a>
                                              </div>
                                          </div>
                                          <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                                              <div class="single-category-widget">
                                                  <div class="icon">
                                                      <i class='bx bx-line-chart'></i>
                                                  </div>
                                                  <h3>Business</h3>
                                                  <span class="sub-title">49 Courses</span>
                                                  <a routerLink="/courses-6" class="link-btn"></a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </li>-->
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Academic <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item"><a routerLink="/admission-procedure"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Admission
                                                    Procedure</a>
                                                </li>
                                                <li class="nav-item"><a routerLink="/credit-transfer-policy"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Credit
                                                    Transfer Policy</a>
                                                </li>
                                                <li class="nav-item"><a routerLink="/latest-entry-policy"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Latest
                                                    Entry Policy</a></li>
                                                <li class="nav-item megamenu"><a href="javascript:void(0)"
                                                                                 class="nav-link">Fee Structure <i
                                                    class='bx bx-chevron-right'></i></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <ul class="megamenu-submenu">
                                                                            <li>
                                                                                <a routerLink="/for-session-2021-22">For-Session-2021-22</a>
                                                                            </li>
                                                                            <li>
                                                                                <a routerLink="/for-session-2021-23">For-Session-2021-23</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="nav-item"><a
                                                    href="https://subhartidde.com/documents/FeeRefundPolicy.pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Refund
                                                    Policy</a></li>
                                                <li class="nav-item"><a routerLink="/semester-syllabus" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Semester
                                                    Syllabus 2021</a></li>
                                                <li class="nav-item"><a routerLink="/detailed-syllabus" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Detailed
                                                    Syllabus</a></li>
                                                <li class="nav-item"><a routerLink="/assignments" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Assignments </a>
                                                </li>
                                                <li class="nav-item"><a routerLink="/guidelines" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Guidelines </a>
                                                </li>
                                                <li class="nav-item"><a routerLink="/academic-calender" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Academic
                                                    Calender </a></li>
                                                <li class="nav-item"><a routerLink="/kitchen-coaching" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Academic
                                                    Details </a></li>
                                                <li class="nav-item"><a routerLink="/course-slm" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Course
                                                    SLM's
                                                </a></li>
                                                <li class="nav-item"><a href="https://subhartidde.com/documents/SLM.pdf"
                                                                        target="_blank"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">SLM
                                                    Details
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Examinations <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item"><a routerLink="/examination-schedule"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Examination
                                                    Schedule</a></li>
                                                <li class="nav-item"><a routerLink="/evalution-procedure"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Evalution
                                                    Procedure</a></li>
                                                <li class="nav-item"><a
                                                    href="https://subhartidde.com/documents/ExamCenter-2021-22.pdf"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Examination
                                                    Centers</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!--  <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                              <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"
                                                      [routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                              <li class="nav-item"><a routerLink="/single-products" class="nav-link"
                                                      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Product
                                  Details</a></li>-->
                        </ul>
                    </li>
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Student Support
                        <i
                            class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item"><a routerLink="/downloads" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Downloads</a>
                                                </li>
                                                <li class="nav-item"><a routerLink="/course-co-ordinators"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Course
                                                    Co-Ordinators</a></li>
                                                <li class="nav-item"><a routerLink="/contact" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Enquiry/Grievance
                                                    Form</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Results <i
                        class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item"><a href="http://182.74.167.41/studentlogin.aspx"
                                                                        target="_blank"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Student
                                                    Login</a></li>
                                                <li class="nav-item"><a
                                                    href="http://182.74.167.61:444/Account/StudentLogin.aspx"
                                                    target="_blank" class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Student
                                                    Login(C 22/A 22-23)</a></li>
                                                <!--  <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"
                                                                          [routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                                  <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"
                                                                          [routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                                                  <li class="nav-item"><a routerLink="/single-products" class="nav-link"
                                                                          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Product
                                                      Details</a></li>-->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center d-none d-lg-block">
                <!--  <div class="option-item">
                      <div class="cart-btn">
                          <a routerLink="/cart"><i class='flaticon-shopping-cart'></i><span>3</span></a>
                      </div>
                  </div>-->
                <div class="option-item">
                    <a href="https://subharti.org/distance/admission/" target="_blank" class="default-btn px-4"
                       style="background-color: #86bc42;padding: 3px; font-weight: 300;">Apply
                        Now<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div>
