<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>ASSIGNMENTS</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Assignments for
                        Calendar Batch '2018' Third Year</h4>
                    <style>
                        p {
                            text-align: justify
                        }
                    </style>
                    <div class="rightcategory_box">
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/ADBA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for ADBA</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/ADCA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for ADCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/B.Com.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.Com</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/B.Sc(PCM).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.Sc(PCM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/B.Sc(ZBC).pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for B.Sc(ZBC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Compulsory.pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for B.A.(Compulsory)</a></h6>


                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Economics.pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(Economics)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Hindi.pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for B.A.(Hindi)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-History.pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(History)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Political Science.pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(Political
                                        Science)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Sociology.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Sociology)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-English.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(English)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Fine-Art.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Fine Art)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Fashion-Designing.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Fashion
                                        Designing)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-Math.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Math)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BA-JMC.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BBA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for BBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/BCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for BCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/DBA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for DBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/DCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for DCA</a></h6>


                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MBA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for MBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/M.Com.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Com</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/M.Lib.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Lib</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MSc(CA).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Sc (CA)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MSC(DFSM).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Sc(DFSM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MA-Hindi.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Hindi)</a></h6>


                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MA-English.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for M.A(English)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MA-History.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(History)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MA-PUBLIC ADMINISTRATION.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Public Admin)</a>
                                </h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MA-Political Science.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for M.A(Political
                                        Science)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MA-Sociology.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Sociology)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MA-JMC.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(JMC)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/MCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for MCA</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/PGDIPR.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for PGDIPR</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="documents/Assignments/2 Year/PGDCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for PGDCA</a></h6>

                            </div>
                        </div>


                        <br class="clear">
                    </div>
                    <style>
                        /*
            Generic Styling, for Desktops/Laptops
            */
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }

                        /* Zebra striping */
                        tr:nth-of-type(odd) {
                            background: #eee;
                        }

                        th {
                            background: #333;
                            color: white;
                            font-weight: bold;
                        }

                        td, th {
                            padding: 6px;
                            border: 1px solid #ccc;
                            text-align: left;
                        }

                        /*
                        Max width before this PARTICULAR table gets nasty
                        This query will take effect for any screen smaller than 760px
                        and also iPads specifically.
                        */
                        @media only screen and (max-width: 760px),
                        (min-device-width: 768px) and (max-device-width: 1024px) {

                            /* Force table to not be like tables anymore */
                            table, thead, tbody, th, td, tr {
                                display: block;
                            }

                            /* Hide table headers (but not display: none;, for accessibility) */
                            thead tr {
                                position: absolute;
                                top: -9999px;
                                left: -9999px;
                            }

                            tr {
                                border: 1px solid #ccc;
                            }

                            td {
                                /* Behave  like a "row" */
                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                padding-left: 50%;
                            }

                            td:before {
                                /* Now like a table header */
                                position: absolute;
                                /* Top/left values mimic padding */
                                top: 6px;
                                left: 6px;
                                width: 45%;
                                padding-right: 10px;
                                white-space: nowrap;
                            }

                            /*
                            Label the data
                            */
                            td:nth-of-type(1):before {
                                content: "First Name";
                            }

                            td:nth-of-type(2):before {
                                content: "Last Name";
                            }

                            td:nth-of-type(3):before {
                                content: "Job Title";
                            }

                            td:nth-of-type(4):before {
                                content: "Favorite Color";
                            }

                            td:nth-of-type(5):before {
                                content: "Wars of Trek?";
                            }

                            td:nth-of-type(6):before {
                                content: "Secret Alias";
                            }

                            td:nth-of-type(7):before {
                                content: "Date of Birth";
                            }

                            td:nth-of-type(8):before {
                                content: "Dream Vacation City";
                            }

                            td:nth-of-type(9):before {
                                content: "GPA";
                            }

                            td:nth-of-type(10):before {
                                content: "Arbitrary Data";
                            }
                        }
                    </style>
                </div>
            </div>
        </div>
    </div>
</div>

