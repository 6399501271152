import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-examination-schedule',
  templateUrl: './examination-schedule.component.html',
  styleUrls: ['./examination-schedule.component.scss']
})
export class ExaminationScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
