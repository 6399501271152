<div class="blog-area pt-100 pb-70">
    <div class="d-none d-lg-block" style="margin-top: -55px;"></div>
    <div class="container">
        <div class="section-title dividerHeading d-none d-lg-block">
            <h4 class=""><span style="font-size: 25px;font-weight: 700;">Latest Blog</span></h4>
           <!--  <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>-->
        </div>
        <div class="section-title d-lg-none">
            <h4 class=""><span style="font-size: 25px;font-weight: 700;">Latest Blog</span></h4>
            <!-- <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>-->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="single-post-box">
                    <div class="post-image">
                        <a routerLink="/how-to-get-admission-open" class="d-block">
                            <img src="assets/img/blog/how-to-get-admission-open.png" alt="image">
                        </a>
                    </div>
                    <div class="post-content shadow-lg p-3 mb-5 bg-white rounded" style="margin-top: -32px;">
                        <!-- <ul class="meta d-flex justify-content-between align-items-center">
                             <li><a routerLink="/blog-2">Business</a></li>
                             <li><i class='flaticon-calendar'></i> April 29, 2021</li>
                         </ul>-->
                        <h3 class="inter-font"><a routerLink="/how-to-get-admission-open"
                                                  style="font-weight: 400;font-size: 18px;">How to get admission in
                            Subharti
                            University?</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <!--<img src="assets/img/user/user1.jpg" class="rounded-circle" alt="image">-->
                                    <a routerLink="/how-to-get-admission-open"> <span style="font-weight: normal;">In 2008, Swami Vivekananda Subharti University was founded in Meerut, Uttar Pradesh.</span></a>
                                </div>
                            </li>
                            <li><a routerLink="/how-to-get-admission-open" class="link-btn"><i
                                class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-post-box">
                    <div class="post-image">
                        <a routerLink="/is-subharti-university-good-for-distance-mba" class="d-block">
                            <img src="assets/img/blog/is-subharti-good.png" alt="image">
                        </a>
                    </div>
                    <div class="post-content shadow-lg p-3 mb-5 bg-white rounded" style="margin-top: -32px;">
                        <!--  <ul class="meta d-flex justify-content-between align-items-center">
                              <li><a routerLink="/blog-2">Education</a></li>
                              <li><i class='flaticon-calendar'></i> April 28, 2021</li>
                          </ul>-->
                        <h3 class="inter-font"><a routerLink="/is-subharti-university-good-for-distance-mba"
                                                  style="font-weight: 400;font-size: 18px;">Is Subharti
                            University Good for Distance
                            MBA</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <!--<img src="assets/img/user/user2.jpg" class="rounded-circle" alt="image">-->
                                    <a routerLink="/is-subharti-university-good-for-distance-mba"><span
                                        style="font-weight: normal;">Subharti, which was founded in 2008, offers India's best distance learning programme.</span>
                                    </a>
                                </div>
                            </li>
                            <li><a routerLink="/is-subharti-university-good-for-distance-mba" class="link-btn"><i
                                class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-post-box">
                    <div class="post-image">
                        <a routerLink="/why-choose-subharti-university-for-distance-learning" class="d-block">
                            <img src="assets/img/blog/why-choose-subharti.png" alt="image">
                        </a>
                    </div>
                    <div class="post-content shadow-lg p-3 mb-5 bg-white rounded" style="margin-top: -32px;">
                        <!--   <ul class="meta d-flex justify-content-between align-items-center">
                               <li><a routerLink="/blog-2">Coaching</a></li>
                               <li><i class='flaticon-calendar'></i> April 27, 2021</li>
                           </ul>-->
                        <h3 class="inter-font"><a routerLink="/why-choose-subharti-university-for-distance-learning"
                                                  style="font-weight: 400;font-size: 18px;">Why
                            choose Subharti university for Distance learning?</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <!--<img src="assets/img/user/user3.jpg" class="rounded-circle" alt="image">-->
                                    <a routerLink="/why-choose-subharti-university-for-distance-learning"> <span
                                        style="font-weight: normal;">One of Uttar Pradesh's top public universities is Subharti University.</span>

                                    </a>
                                </div>
                            </li>
                            <li><a routerLink="/why-choose-subharti-university-for-distance-learning"
                                   class="link-btn"><i
                                class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-post-box">
                    <div class="post-image">
                        <a routerLink="/is-subharti-university-degree-valid-for-government-job" class="d-block">
                            <img src="assets/img/blog/is-subharti-uni-degree-valid.png" alt="image">
                        </a>
                    </div>
                    <div class="post-content shadow-lg p-3 mb-5 bg-white rounded" style="margin-top: -32px;">
                        <!--   <ul class="meta d-flex justify-content-between align-items-center">
                               <li><a routerLink="/blog-2">Coaching</a></li>
                               <li><i class='flaticon-calendar'></i> April 27, 2021</li>
                           </ul>-->
                        <h3 class="inter-font"><a
                            routerLink="/is-subharti-university-degree-valid-for-government-job"
                            style="font-weight: 400;font-size: 18px;">Is
                            Subharti's university degree valid for government jobs?</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <!--<img src="assets/img/user/user3.jpg" class="rounded-circle" alt="image">-->
                                    <a routerLink="/is-subharti-university-degree-valid-for-government-job"> <span
                                        style="font-weight: normal;">In 2008, Swami Vivekanand Subharti University was created..</span>
                                    </a>
                                </div>
                            </li>
                            <li><a routerLink="/is-subharti-university-degree-valid-for-government-job"
                                   class="link-btn"><i
                                class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
