import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-transfer-policy',
  templateUrl: './credit-transfer-policy.component.html',
  styleUrls: ['./credit-transfer-policy.component.scss']
})
export class CreditTransferPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
