<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>RECOGNITIONS</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Recognitions</h4>
                    <style>
                        p {
                            text-align: justify
                        }
                    </style>
                    <div align="center" class="rightcategory_box">
                        <img src="assets/img/pdf/18-20_2.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/letter-03-1.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/letter-03-1.jpg" height="900px" width="700px">

                        <img src="assets/img/pdf/Letter-1.jpeg" height="900px" width="700px">
                        <img src="assets/img/pdf/Letter-2.jpeg" height="900px" width="700px">
                        <img src="assets/img/pdf/Letter-3.jpeg" height="900px" width="700px">

                        <img src="assets/img/pdf/18-20_1.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/18-20_2.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/18-20_3.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/18-20_4.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R18.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R17.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R16.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R15.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R14.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R13.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R12.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R11.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R10.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R9.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R8.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R7.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R6.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R5.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R4.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R3.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R2.jpg" height="900px" width="700px">
                        <img src="assets/img/pdf/R1.jpg" height="900px" width="700px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
