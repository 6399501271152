import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ma-education',
  templateUrl: './ma-education.component.html',
  styleUrls: ['./ma-education.component.scss']
})
export class MaEducationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
