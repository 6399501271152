<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>POST GRADUATE DIPLOMA COURSES</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>POST GRADUATE DIPLOMA COURSES</h3>
                        <div style="overflow-x:auto;">
                            <table style="border: 1px solid black;">
                                <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Title of Programme</th>
                                    <th>Eligibility</th>
                                    <th>Min Course Duration</th>
                                    <th>Max Course Duration</th>
                                    <th>Annual Fees Per Year</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>
                                        Post Graduate Diploma in Intellectual Property Rights
                                    </td>
                                    <td>Graduation or eq.</td>
                                    <td>1 Year</td>
                                    <td>3 Years</td>
                                    <td>8500</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>Post Graduate Diploma in Dietetics & Public Nutrition
                                    </td>
                                    <td>B.Sc Food & Nutrition or eq.</td>
                                    <td>1 Year</td>
                                    <td>3 Year</td>
                                    <td>14000</td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>
                                        Post Graduate Diploma in Maternal & Child Health
                                    </td>
                                    <td>MBBS or eq.</td>
                                    <td>1 Year
                                    </td>
                                    <td>3 Year</td>
                                    <td>19000</td>
                                </tr>
                                <tr>
                                    <td>4.</td>
                                    <td>
                                        Post Graduate Diploma in Food Safety & Quality Management
                                    </td>
                                    <td>
                                        Graduation in Science/ Food Sc.
                                    </td>
                                    <td>1 Year</td>
                                    <td>3 Year</td>
                                    <td>14000</td>
                                </tr>
                                <tr>
                                    <td>5.</td>
                                    <td>
                                        Post Graduate Diploma in Hospital & Health Management
                                    </td>
                                    <td>Medical & Dental Graduates with 3 years of experience or MBA/PG candidates in
                                        Financial, Material or Personal Mgmt with 5 year of Hospital Experience
                                    </td>
                                    <td>1 Year</td>
                                    <td>3 Year</td>
                                    <td>19000</td>
                                </tr>
                                <tr>
                                    <td>6.</td>
                                    <td>
                                        Post Graduate Diploma in Computer Application
                                    </td>
                                    <td>Graduation or eq.</td>
                                    <td>1 Year</td>
                                    <td>3 Year</td>
                                    <td>12000</td>
                                </tr>
                                <tr>
                                    <td>7.</td>
                                    <td>
                                        Post Graduate Certificate in Patent Practice
                                    </td>
                                    <td>LLB/Graduation in Law or eq.</td>
                                    <td>1 Year</td>
                                    <td>3 Year</td>
                                    <td>
                                        9000
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>* Maximum duration refers to the Maximum time allowed for the completion of that course. If
                            the student fails to complete it within this period full fees will have to be paid
                            again.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
