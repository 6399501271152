import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-enquiry',
    templateUrl: './enquiry.component.html',
    styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    bgImage = [
        {
            img: 'assets/img/register-shape.jpg'
        }
    ]

    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var message = form.message;
        console.log(message);
    }

}
