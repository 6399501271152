import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-distance-banner',
    templateUrl: './distance-banner.component.html',
    styleUrls: ['./distance-banner.component.scss']
})
export class DistanceBannerComponent implements OnInit {

    constructor() {
    }

    mainBannerSliders: any = [
        {
            img: 'assets/img/banner/main-banner_1.jpeg'
        },
        {
            img: 'assets/img/banner/main-banner_2.jpeg'
        }
    ];

    bgImage = [
        {
            img: 'assets/img/main-banner1.jpg'
        }
    ];
   /* kitchenFeedbackSlides: OwlOptions = {
        items: 1,
        loop: true,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            '<i class=\'bx bx-chevron-left\'></i>',
            '<i class=\'bx bx-chevron-right\'></i>'
        ]
    };*/
    kitchenFeedbackSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        autoHeight: true,
        smartSpeed: 800,
        navText: [
            '<i class="bx bx-chevron-left"></i>',
            '<i class="bx bx-chevron-right"></i>'
        ]
    };

    ngOnInit(): void {
    }

}
