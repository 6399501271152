import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-world-of-subharti',
  templateUrl: './world-of-subharti.component.html',
  styleUrls: ['./world-of-subharti.component.scss']
})
export class WorldOfSubhartiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
