import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-act-and-statutes',
  templateUrl: './act-and-statutes.component.html',
  styleUrls: ['./act-and-statutes.component.scss']
})
export class ActAndStatutesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
