import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enq',
  templateUrl: './enq.component.html',
  styleUrls: ['./enq.component.scss']
})
export class EnqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
