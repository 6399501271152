import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-syllabus',
  templateUrl: './new-syllabus.component.html',
  styleUrls: ['./new-syllabus.component.scss']
})
export class NewSyllabusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
