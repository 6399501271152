<div class="kitchen-features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title dividerHeading d-none d-lg-block">
            <!--<span class="sub-title">EDUCATION FOR EVERYONE</span>-->
            <h4 class=""><span style="font-weight: 700; font-size: 25px;">UNIVERSITY'S TOP FACILITIES</span></h4>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="section-title  d-lg-none">
            <!--<span class="sub-title">EDUCATION FOR EVERYONE</span>-->
            <h4 class=""><span style="font-weight: 700; font-size: 25px;">UNIVERSITY'S TOP FACILITIES</span></h4>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/250_acre_.png" style="height: 60px;">
                    </div>
                    <h3>250 Acre Campus</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/finest_faculty.png" style="height: 60px;">
                    </div>
                    <h3>Finest Faculties</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/wifi_campus.png" style="height: 60px;">
                    </div>
                    <h3>Wi-Fi Campus</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/residential_facilities.png" style="height: 60px;">
                    </div>
                    <h3>Residential Facilities</h3>
                  <!--  <div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/banking_services.png" style="height: 60px;">
                    </div>
                    <h3>Banking Services</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/transportation.png" style="height: 60px;">
                    </div>
                    <h3>Transport Facilities</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/ragging.png" style="height: 60px;">
                    </div>
                    <h3>Ragging Free Campus</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/world_class_infrastructure.png" style="height: 60px;">
                    </div>
                    <h3>World Class Infrastructure</h3>
                   <!-- <div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/air_conditioned_hostel.png" style="height: 60px;">
                    </div>
                    <h3>Air Conditioned Hostels</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/medicare.png" style="height: 60px;">
                    </div>
                    <h3>Medicare Facilities</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/canteen.png" style="height: 60px;">
                    </div>
                    <h3>Canteens & Cafeterias</h3>
                    <!--<div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box" style="text-align: center; height: 160px;">
                    <div class="icon">
                        <img src="assets/img/icons/gym.png" style="height: 60px;">
                    </div>
                    <h3>Gymnasiums</h3>
                  <!--  <div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>-->
                </div>
            </div>
            <!--<div class="col-lg-2 col-sm-1 col-md-2">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                    <div class="back-icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-1 col-md-2">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                    </div>
                    <h3>Learn Anywhere in the World</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                    <div class="back-icon">
                        <i class="flaticon-world"></i>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</div>
