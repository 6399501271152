<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ABOUT UNIVERSITY</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>ABOUT UNIVERSITY</h3>
                        <p>Swami Vivekanand Subharti University is a University under Section 2(f) of the University
                            Grants Commission Act, 1956 set up under the Swami Vivekanand Subharti Vishwavidyalaya Uttar
                            Pradesh Adhiniyam, 2008 (U.P. Act No. 29 of 2008) as passed by the Uttar Pradesh Legislature
                            and assented to by the Hon'ble Governor of Uttar Pradesh in September 2008.</p>
                        <p>The University has been established under the aegis of Subharti K.K.B. Charitable Trust,
                            Meerut, which has acquired a commendable record of service in the field of Education, Health
                            care and Social welfare.</p>
                        <p>The main campus of the University is in the National Capital Region, strategically situated
                            on National Highway 58, Delhi-Meerut-Haridwar Bypass Road, Meerut. The campus, aptly called
                            Subhartipuram, is spread over a sprawling area of about 250 acres of land comprising
                            magnificent buildings, lush green lawns and vibrant surroundings with over 8000 people,
                            determined to make this a ‘Jewel’ in the Crown of the Nation.</p>
                        <p>The University has several constituent colleges which provide higher education in almost all
                            the disciplines like Medical, Dental, Paramedical, Pharmacy, Engineering, Management, Law,
                            Journalism, Education, Arts and Science, thus engaged in creating highly qualified,
                            academically and technically proficient professionals.</p>
                        <p>The University has also started a number of courses through Distance Education, approved by
                            Joint Committee of UGC, AICTE and DEC.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
