import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignment10',
  templateUrl: './assignment10.component.html',
  styleUrls: ['./assignment10.component.scss']
})
export class Assignment10Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
