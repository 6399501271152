<footer class="footer-area">
    <div class="container" style="margin-top: -50px;">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-none d-lg-block" style="margin-left: -33px;">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/contrast_logo_v01.png" alt="logo"
                                                        style="height: 47px;"></a>
                    <p>The Distance Education Council of Government of India has accorded recognition to the
                        University
                        for offering programmes through distance education mode</p>
                    <br>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/subhartiuni" class="d-block" target="_blank"><i
                            class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/subhartiuni.official/" class="d-block" target="_blank"><i
                            class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://twitter.com/subhartiofficia" class="d-block" target="_blank"><i
                            class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCg9Upto51X-DRkibtkRKryQ" class="d-block"
                               target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-lg-none">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/banner/footer-banner.png" alt="logo"
                                                        style="height: 47px;"></a>
                    <p>The Distance Education Council of <br>Government of India has accorded <br>recognition to the
                        University
                        for <br>offering programmes through <br>distance education mode</p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/subhartiuni" class="d-block" target="_blank"><i
                            class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/subhartiofficia" class="d-block" target="_blank"><i
                            class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCg9Upto51X-DRkibtkRKryQ" class="d-block"
                               target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        <li><a href="https://www.instagram.com/subhartiuni.official/" class="d-block" target="_blank"><i
                            class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center">
                <div class="single-footer-widget">
                    <h3>About Us</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/dde">DDE</a></li>
                        <li><a routerLink="/university">University</a></li>
                        <li><a routerLink="/director-messege">Director's Messege</a></li>
                        <li><a href="https://subhartidde.com/documents/Resolution-for-DDE.pdf" target="_blank">Resolution
                            for DDE</a></li>
                        <li><a routerLink="/compliance">DEB Compliance</a></li>
                        <li><a routerLink="/vision-mission">Vision & Mission</a></li>
                        <li><a routerLink="/world-of-subharti">World of Subharti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-none d-lg-block" style="margin-left: -1px;">
                <div class="single-footer-widget">
                    <h3>ACADEMIC</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/admission-procedure">Admission Procedure</a></li>
                        <li><a routerLink="/credit-transfer-policy">Credit Transfer Policy</a></li>
                        <li><a routerLink="/latest-entry-policy">Lateral Entry Policy</a></li>
                        <li><a routerLink="/for-session-2021-23">Fee Structure</a></li>
                        <li><a href="https://subhartidde.com/documents/FeeRefundPolicy.pdf" target="_blank">Refund
                            Policy</a></li>
                        <li><a routerLink="/detailed-syllabus">Detailed Syllabus</a></li>
                        <li><a routerLink="/academic-calender">Academic Calendar</a></li>
                        <li><a href="http://182.74.167.41/StudentLogin.aspx" target="_blank">Student Login</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-lg-none">
                <div class="single-footer-widget">
                    <h3>ACADEMIC</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/admission-procedure">Admission Procedure</a></li>
                        <li><a routerLink="/credit-transfer-policy">Credit Transfer Policy</a></li>
                        <li><a routerLink="/latest-entry-policy">Lateral Entry Policy</a></li>
                        <li><a routerLink="/for-session-2021-23">Fee Structure</a></li>
                        <li><a href="https://subhartidde.com/documents/FeeRefundPolicy.pdf" target="_blank">Refund
                            Policy</a></li>
                        <li><a routerLink="/detailed-syllabus">Detailed Syllabus</a></li>
                        <li><a routerLink="/academic-calender">Academic Calendar</a></li>
                        <li><a href="http://182.74.167.41/StudentLogin.aspx" target="_blank">Student Login</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-none d-lg-block" style="margin-left: 33px;">
                <div class="single-footer-widget">
                    <h3>PORTALS</h3>
                    <ul class="footer-contact-info">
                        <li><!--<i class='bx bx-map'></i>--><a href="https://subhartidde.com/documents/CHM.pdf"
                                                               target="_blank">Complaint Handling Mechanism</a></li>
                        <li><!--<i class='bx bx-phone-call'></i>--><a routerLink="/grievance">Grievance Redressal
                            System</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a routerLink="/feedback">Feedback</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a href="https://subhartidde.com/documents/FAQ_New.pdf"
                                                                    target="_blank">FAQ'S</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a href="http://182.74.167.41/StudentLogin.aspx"
                                                                    target="_blank">Result</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a routerLink="/contact">Contact Us</a></li>
                        <!--<li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-lg-none">
                <div class="single-footer-widget">
                    <h3>PORTALS</h3>
                    <ul class="footer-contact-info">
                        <li><!--<i class='bx bx-map'></i>--><a href="https://subhartidde.com/documents/CHM.pdf"
                                                               target="_blank">Complaint Handling Mechanism</a></li>
                        <li><!--<i class='bx bx-phone-call'></i>--><a routerLink="/grievance">Grievance Redressal
                            System</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a routerLink="/feedback">Feedback</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a href="https://subhartidde.com/documents/FAQ_New.pdf"
                                                                    target="_blank">FAQ'S</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a href="http://182.74.167.41/StudentLogin.aspx"
                                                                    target="_blank">Result</a></li>
                        <li><!--<i class='bx bx-envelope'></i>--><a routerLink="/contact">Contact Us</a></li>
                        <!--<li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li>-->
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area" style="margin-top: -20px;">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>Subhartiddeis Proudly Powered by <a>Subharti
                        University</a>
                    </p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a>Privacy Policy</a></li>
                        <li><a>Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
