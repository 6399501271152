<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ASSIGNMENTS</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area ptb-100 bg-f5f7fa">
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="about-text-container">

                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Assignments</h4>

                    <style>

                        p {
                            text-align: justify
                        }

                    </style>

                    <div class="rightcategory_box">

                        <b>

                            <br>
                            <a routerLink="/semester-syllabus">Assignments for Exam 2022 - 5th Semester</a><br>

                            <br>
                            <a routerLink="/semester-syllabus">Assignments for Exam 2022 - 4th Semester</a><br>

                            <br>
                            <a routerLink="/semester-syllabus">Assignments for Exam 2022 - 3rd Semester</a><br>

                            <br>
                            <a routerLink="/semester-syllabus">Assignments for Exam 2022 - 2nd Semester</a><br>

                            <br>
                            <a routerLink="/semester-syllabus">Assignments for Exam 2022 - 1st Semester</a><br>

                            <br>
                            <a routerLink="/assignment9">Assignment of Academic Batch- 2021-2022 (III
                                YEAR)</a><br>

                            <br>
                            <a routerLink="/assignment8">Assignment of Academic Batch- 2019 (III YEAR)</a><br>

                            <br>

                            <a routerLink="/assignment7">Assignment of Academic Batch- 2020 (II YEAR)</a><br>

                            <br>

                            <a routerLink="/admission-details">Assignment of Academic Batch-2018-19(III YEAR)</a><br>

                            <br>

                            <a routerLink="/assignment6">Assignment of Academic Batch-2019-20(II YEAR)</a><br>


                            <br>

                            <a routerLink="/assignment5">Assignment of Calendar Batch-2020(II Semester).</a>

                            <br>

                            <br>


                            <a routerLink="/assignment4">Assignment of Academic Batch-2021(I Semester).</a>
                            <br>
                            <br>
                            <a routerLink="/assignment3">Assignment's of Academic Batch-2021-22-I Semester.</a>
                            <br>


                            <br>
                            <a routerLink="/assignment6">Assignment's of Academic Batch-2021-22-IISemester.</a>
                            <br>
                            <br>

                            <a routerLink="/assignment5">Assignment's of Academic
                                Batch-2021-22-IIISemester.</a>


                            <br>
                            <br>


                            <a routerLink="/assignment4">Assignment's of Academic Batch-2021-22-IVSemester.</a>
                            <br>
                            <br>
                            <a routerLink="/assignment3">Calendar Batch '2019' Second Year.</a>

                            <br>

                            <br>

                            <a routerLink="/assignment2">Calendar Batch '2018' Third Year.</a>

                            <br>

                            <br>

                        </b>


                        <br>

                        <b>Note :</b> Please download and install hindi font "Kruti Dev 011" to view the assignments

                        of Hindi Subjects.To download this font click on following link.

                        <br>

                        <div align="center">

                            <a routerLink="/assignment1"><b>Download Hindi Font "Kruti Dev 011"</b></a>

                        </div>

                    </div>

                    <style>

                        /*

            Generic Styling, for Desktops/Laptops

            */

                        table {

                            width: 100%;

                            border-collapse: collapse;

                        }

                        /* Zebra striping */

                        tr:nth-of-type(odd) {

                            background: #eee;

                        }

                        th {

                            background: #333;

                            color: white;

                            font-weight: bold;

                        }

                        td, th {

                            padding: 6px;

                            border: 1px solid #ccc;

                            text-align: left;

                        }

                        /*

                        Max width before this PARTICULAR table gets nasty

                        This query will take effect for any screen smaller than 760px

                        and also iPads specifically.

                        */

                        @media only screen and (max-width: 760px),

                        (min-device-width: 768px) and (max-device-width: 1024px) {


                            /* Force table to not be like tables anymore */
                            table, thead, tbody, th, td, tr {

                                display: block;

                            }


                            /* Hide table headers (but not display: none;, for accessibility) */
                            thead tr {

                                position: absolute;

                                top: -9999px;

                                left: -9999px;

                            }


                            tr {
                                border: 1px solid #ccc;
                            }


                            td {

                                /* Behave  like a "row" */

                                border: none;

                                border-bottom: 1px solid #eee;

                                position: relative;

                                padding-left: 50%;

                            }


                            td:before {

                                /* Now like a table header */

                                position: absolute;

                                /* Top/left values mimic padding */

                                top: 6px;

                                left: 6px;

                                width: 45%;

                                padding-right: 10px;

                                white-space: nowrap;

                            }


                            /*

                            Label the data

                            */
                            td:nth-of-type(1):before {
                                content: "First Name";
                            }

                            td:nth-of-type(2):before {
                                content: "Last Name";
                            }

                            td:nth-of-type(3):before {
                                content: "Job Title";
                            }

                            td:nth-of-type(4):before {
                                content: "Favorite Color";
                            }

                            td:nth-of-type(5):before {
                                content: "Wars of Trek?";
                            }

                            td:nth-of-type(6):before {
                                content: "Secret Alias";
                            }

                            td:nth-of-type(7):before {
                                content: "Date of Birth";
                            }

                            td:nth-of-type(8):before {
                                content: "Dream Vacation City";
                            }

                            td:nth-of-type(9):before {
                                content: "GPA";
                            }

                            td:nth-of-type(10):before {
                                content: "Arbitrary Data";
                            }

                        }

                    </style>

                </div>

            </div>
        </div>
    </div>
</div>
