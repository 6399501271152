import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ElearningSchoolComponent} from './components/pages/elearning-school/elearning-school.component';
import {VendorCertificationEtrainingComponent} from './components/pages/vendor-certification-etraining/vendor-certification-etraining.component';
import {OnlineTrainingSchoolComponent} from './components/pages/online-training-school/online-training-school.component';
import {DistanceLearningComponent} from './components/pages/distance-learning/distance-learning.component';
import {LanguageSchoolComponent} from './components/pages/language-school/language-school.component';
import {ModernSchoolingComponent} from './components/pages/modern-schooling/modern-schooling.component';
import {YogaTrainingComponent} from './components/pages/yoga-training/yoga-training.component';
import {HealthCoachingComponent} from './components/pages/health-coaching/health-coaching.component';
import {KindergartenComponent} from './components/pages/kindergarten/kindergarten.component';
import {ContactUsComponent} from './components/pages/contact-us/contact-us.component';
import {GalleryComponent} from './components/pages/gallery/gallery.component';
import {AboutStyleOneComponent} from './components/pages/about-style-one/about-style-one.component';
import {AboutStyleTwoComponent} from './components/pages/about-style-two/about-style-two.component';
import {AboutStyleThreeComponent} from './components/pages/about-style-three/about-style-three.component';
import {AboutStyleFourComponent} from './components/pages/about-style-four/about-style-four.component';
import {SuccessStoryComponent} from './components/pages/success-story/success-story.component';
import {TeacherComponent} from './components/pages/teacher/teacher.component';
import {ProfileAuthenticationComponent} from './components/pages/profile-authentication/profile-authentication.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {ComingSoonComponent} from './components/pages/coming-soon/coming-soon.component';
import {PurchaseGuideComponent} from './components/pages/purchase-guide/purchase-guide.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {TermsOfServiceComponent} from './components/pages/terms-of-service/terms-of-service.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {CoursesStyleOneComponent} from './components/pages/courses-style-one/courses-style-one.component';
import {CoursesStyleTwoComponent} from './components/pages/courses-style-two/courses-style-two.component';
import {CoursesStyleThreeComponent} from './components/pages/courses-style-three/courses-style-three.component';
import {CoursesStyleFourComponent} from './components/pages/courses-style-four/courses-style-four.component';
import {CoursesStyleFiveComponent} from './components/pages/courses-style-five/courses-style-five.component';
import {CoursesStyleSixComponent} from './components/pages/courses-style-six/courses-style-six.component';
import {CoursesStyleSevenComponent} from './components/pages/courses-style-seven/courses-style-seven.component';
import {CoursesDetailsStyleOneComponent} from './components/pages/courses-details-style-one/courses-details-style-one.component';
import {CoursesDetailsStyleTwoComponent} from './components/pages/courses-details-style-two/courses-details-style-two.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {ProfileQuizComponent} from './components/pages/profile-quiz/profile-quiz.component';
import {MembershipLevelsComponent} from './components/pages/membership-levels/membership-levels.component';
import {BecomeATeacherComponent} from './components/pages/become-a-teacher/become-a-teacher.component';
import {CategoriesComponent} from './components/pages/categories/categories.component';
import {EventsComponent} from './components/pages/events/events.component';
import {EventsDetailsComponent} from './components/pages/events-details/events-details.component';
import {ProductsListStyleOneComponent} from './components/pages/products-list-style-one/products-list-style-one.component';
import {ProductsListStyleTwoComponent} from './components/pages/products-list-style-two/products-list-style-two.component';
import {CartComponent} from './components/pages/cart/cart.component';
import {CheckoutComponent} from './components/pages/checkout/checkout.component';
import {ProductsDetailsComponent} from './components/pages/products-details/products-details.component';
import {BlogStyleOneComponent} from './components/pages/blog-style-one/blog-style-one.component';
import {BlogStyleTwoComponent} from './components/pages/blog-style-two/blog-style-two.component';
import {BlogStyleThreeComponent} from './components/pages/blog-style-three/blog-style-three.component';
import {BlogStyleFourComponent} from './components/pages/blog-style-four/blog-style-four.component';
import {BlogStyleFiveComponent} from './components/pages/blog-style-five/blog-style-five.component';
import {BlogDetailsStyleOneComponent} from './components/pages/blog-details-style-one/blog-details-style-one.component';
import {BlogDetailsStyleTwoComponent} from './components/pages/blog-details-style-two/blog-details-style-two.component';
import {BlogDetailsStyleThreeComponent} from './components/pages/blog-details-style-three/blog-details-style-three.component';
import {GymCoachingComponent} from './components/pages/gym-coaching/gym-coaching.component';
import {LearningManagementComponent} from './components/pages/learning-management/learning-management.component';
import {BusinessCoachingComponent} from './components/pages/business-coaching/business-coaching.component';
import {MotivationalCoachingComponent} from './components/pages/motivational-coaching/motivational-coaching.component';
import {KitchenCoachingComponent} from './components/pages/kitchen-coaching/kitchen-coaching.component';
import {DdeComponent} from './components/pages/dde/dde.component';
import {UniversityComponent} from './components/pages/university/university.component';
import {DirectorMessegeComponent} from './components/pages/director-messege/director-messege.component';
import {WorldOfSubhartiComponent} from './components/pages/world-of-subharti/world-of-subharti.component';
import {AdmissionProcedureComponent} from './components/pages/admission-procedure/admission-procedure.component';
import {CreditTransferPolicyComponent} from './components/pages/credit-transfer-policy/credit-transfer-policy.component';
import {LatestEntryPolicyComponent} from './components/pages/latest-entry-policy/latest-entry-policy.component';
import {NewFeeStructureComponent} from './components/pages/new-fee-structure/new-fee-structure.component';
import {RefundPolicyComponent} from './components/pages/refund-policy/refund-policy.component';
import {SemesterSyllabusComponent} from './components/pages/semester-syllabus/semester-syllabus.component';
import {DetailedSyllabusComponent} from './components/pages/detailed-syllabus/detailed-syllabus.component';
import {AssignmentsComponent} from './components/pages/assignments/assignments.component';
import {DiplomaComponent} from './components/pages/diploma/diploma.component';
import {AdvancedDiplomaComponent} from './components/pages/advanced-diploma/advanced-diploma.component';
import {DegreeCoursesComponent} from './components/pages/degree-courses/degree-courses.component';
import {PgCoursesComponent} from './components/pages/pg-courses/pg-courses.component';
import {PgDiplomaCoursesComponent} from './components/pages/pg-diploma-courses/pg-diploma-courses.component';
import {LatestNewsComponent} from './components/pages/latest-news/latest-news.component';
import {ResutionForDdeComponent} from './components/pages/resution-for-dde/resution-for-dde.component';
import {DebComplianceComponent} from './components/pages/deb-compliance/deb-compliance.component';
import {VisionMissionComponent} from './components/pages/vision-mission/vision-mission.component';
import {FacultyDetailsComponent} from './components/pages/faculty-details/faculty-details.component';
import {DownloadComponent} from './components/pages/download/download.component';
import {EnquiryComponent} from './components/pages/enquiry/enquiry.component';
import {CourseCoOrdinatorsComponent} from './components/pages/course-co-ordinators/course-co-ordinators.component';
import {GuidelinesComponent} from './components/pages/guidelines/guidelines.component';
import {HowToGetAdmissionOpenComponent} from "./components/pages/how-to-get-admission-open/how-to-get-admission-open.component";
import {WhyChooseSubhartiComponent} from "./components/pages/why-choose-subharti/why-choose-subharti.component";
import {IsSubhartiGoodComponent} from "./components/pages/is-subharti-good/is-subharti-good.component";
import {ExaminationScheduleComponent} from "./components/pages/examination-schedule/examination-schedule.component";
import {EvalutionProcedureComponent} from "./components/pages/evalution-procedure/evalution-procedure.component";
import {AllCoursesComponent} from "./components/pages/all-courses/all-courses.component";
import {NewCoursesComponent} from "./components/pages/new-courses/new-courses.component";
import {CourseSlmComponent} from "./components/pages/course-slm/course-slm.component";
import {AdmissionDetailsComponent} from "./components/pages/admission-details/admission-details.component";
import {AcademicCalenderComponent} from "./components/pages/academic-calender/academic-calender.component";
import {SessionTwoComponent} from "./components/pages/session-two/session-two.component";
import {SessionOneComponent} from "./components/pages/session-one/session-one.component";
import {FacultyComponent} from "./components/pages/faculty/faculty.component";
import {IsSubhartiUniversityDegreeValidForGovernmentJobComponent} from "./components/pages/is-subharti-university-degree-valid-for-government-job/is-subharti-university-degree-valid-for-government-job.component";
import {GrievanceRedressalSystemComponent} from "./components/pages/grievance-redressal-system/grievance-redressal-system.component";
import {FeedbackComponent} from "./components/pages/feedback/feedback.component";
import {RecognitionsComponent} from "./components/pages/recognitions/recognitions.component";
import {Assignment1Component} from "./components/pages/assignment1/assignment1.component";
import {Assignment2Component} from "./components/pages/assignment2/assignment2.component";
import {Assignment3Component} from "./components/pages/assignment3/assignment3.component";
import {Assignment4Component} from "./components/pages/assignment4/assignment4.component";
import {Assignment5Component} from "./components/pages/assignment5/assignment5.component";
import {Assignment6Component} from "./components/pages/assignment6/assignment6.component";
import {Assignment7Component} from "./components/pages/assignment7/assignment7.component";
import {Assignment8Component} from "./components/pages/assignment8/assignment8.component";
import {Assignment9Component} from "./components/pages/assignment9/assignment9.component";
import {Assignment10Component} from "./components/pages/assignment10/assignment10.component";
import {Assignment12Component} from "./components/pages/assignment12/assignment12.component";
import {Assignment11Component} from "./components/pages/assignment11/assignment11.component";

const routes: Routes = [
    // {path: '', component: ElearningSchoolComponent},
    {path: '', component: DistanceLearningComponent},
    {path: 'vendor-certification-etraining', component: VendorCertificationEtrainingComponent},
    {path: 'online-training-school', component: OnlineTrainingSchoolComponent},
    {path: 'distance-learning', component: DistanceLearningComponent},
    {path: 'language-school', component: LanguageSchoolComponent},
    {path: 'modern-schooling', component: ModernSchoolingComponent},
    {path: 'yoga-training', component: YogaTrainingComponent},
    {path: 'health-coaching', component: HealthCoachingComponent},
    {path: 'kindergarten', component: KindergartenComponent},
    {path: 'gym-coaching', component: GymCoachingComponent},
    {path: 'learning-management', component: LearningManagementComponent},
    {path: 'business-coaching', component: BusinessCoachingComponent},
    {path: 'motivational-coaching', component: MotivationalCoachingComponent},
    {path: 'kitchen-coaching', component: KitchenCoachingComponent},
    {path: 'about-1', component: AboutStyleOneComponent},
    {path: 'about-2', component: AboutStyleTwoComponent},
    {path: 'about-3', component: AboutStyleThreeComponent},
    {path: 'about-4', component: AboutStyleFourComponent},
    {path: 'success-story', component: SuccessStoryComponent},
    {path: 'teacher', component: TeacherComponent},
    {path: 'profile-authentication', component: ProfileAuthenticationComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'purchase-guide', component: PurchaseGuideComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-of-service', component: TermsOfServiceComponent},
    {path: 'courses-1', component: CoursesStyleOneComponent},
    {path: 'courses-2', component: CoursesStyleTwoComponent},
    {path: 'courses-3', component: CoursesStyleThreeComponent},
    {path: 'courses-4', component: CoursesStyleFourComponent},
    {path: 'courses-5', component: CoursesStyleFiveComponent},
    {path: 'courses-6', component: CoursesStyleSixComponent},
    {path: 'courses-7', component: CoursesStyleSevenComponent},
    {path: 'single-courses-1', component: CoursesDetailsStyleOneComponent},
    {path: 'single-courses-2', component: CoursesDetailsStyleTwoComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'profile-quiz', component: ProfileQuizComponent},
    {path: 'membership-levels', component: MembershipLevelsComponent},
    {path: 'become-a-teacher', component: BecomeATeacherComponent},
    {path: 'categories', component: CategoriesComponent},
    {path: 'events', component: EventsComponent},
    {path: 'single-events', component: EventsDetailsComponent},
    {path: 'products-list-1', component: ProductsListStyleOneComponent},
    {path: 'products-list-2', component: ProductsListStyleTwoComponent},
    {path: 'cart', component: CartComponent},
    {path: 'checkout', component: CheckoutComponent},
    {path: 'single-products', component: ProductsDetailsComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'blog-1', component: BlogStyleOneComponent},
    {path: 'blog-2', component: BlogStyleTwoComponent},
    {path: 'blog-3', component: BlogStyleThreeComponent},
    {path: 'blog-4', component: BlogStyleFourComponent},
    {path: 'blog-5', component: BlogStyleFiveComponent},
    {path: 'single-blog-1', component: BlogDetailsStyleOneComponent},
    {path: 'single-blog-2', component: BlogDetailsStyleTwoComponent},
    {path: 'single-blog-3', component: BlogDetailsStyleThreeComponent},
    {path: 'contact', component: ContactUsComponent},
    {path: 'dde', component: DdeComponent},
    {path: 'university', component: UniversityComponent},
    {path: 'director-messege', component: DirectorMessegeComponent},
    {path: 'world-of-subharti', component: WorldOfSubhartiComponent},
    {path: 'admission-procedure', component: AdmissionProcedureComponent},
    {path: 'credit-transfer-policy', component: CreditTransferPolicyComponent},
    {path: 'latest-entry-policy', component: LatestEntryPolicyComponent},
    {path: 'new-fee-structure', component: NewFeeStructureComponent},
    {path: 'refund-policy', component: RefundPolicyComponent},
    {path: 'semester-syllabus', component: SemesterSyllabusComponent},
    {path: 'detailed-syllabus', component: DetailedSyllabusComponent},
    {path: 'assignments', component: AssignmentsComponent},
    {path: 'assignment1', component: Assignment1Component},
    {path: 'assignment2', component: Assignment2Component},
    {path: 'assignment3', component: Assignment3Component},
    {path: 'assignment4', component: Assignment4Component},
    {path: 'assignment5', component: Assignment5Component},
    {path: 'assignment6', component: Assignment6Component},
    {path: 'assignment7', component: Assignment7Component},
    {path: 'assignment8', component: Assignment8Component},
    {path: 'assignment9', component: Assignment9Component},
    {path: 'assignment10', component: Assignment10Component},
    {path: 'assignment12', component: Assignment12Component},
    {path: 'assignment11', component: Assignment11Component},
    {path: 'diploma', component: DiplomaComponent},
    {path: 'advanced-diploma', component: AdvancedDiplomaComponent},
    {path: 'degree-courses', component: DegreeCoursesComponent},
    {path: 'pg-courses', component: PgCoursesComponent},
    {path: 'pg-diploma-courses', component: PgDiplomaCoursesComponent},
    {path: 'new-courses', component: NewCoursesComponent},
    {path: 'latest-news', component: LatestNewsComponent},
    {path: 'resolution-for-dde', component: ResutionForDdeComponent},
    {path: 'compliance', component: DebComplianceComponent},
    {path: 'vision-mission', component: VisionMissionComponent},
    {path: 'faculty-details', component: FacultyDetailsComponent},
    {path: 'downloads', component: DownloadComponent},
    {path: 'course-slm', component: CourseSlmComponent},
    {path: 'admission-details', component: AdmissionDetailsComponent},
    {path: 'for-session-2021-23', component: SessionTwoComponent},
    {path: 'faculty', component: FacultyComponent},
    {
        path: 'is-subharti-university-degree-valid-for-government-job',
        component: IsSubhartiUniversityDegreeValidForGovernmentJobComponent
    },
    {path: 'for-session-2021-22', component: SessionOneComponent},
    {path: 'recognition', component: RecognitionsComponent},
    {path: 'grievance', component: GrievanceRedressalSystemComponent},
    {path: 'feedback', component: FeedbackComponent},
    {path: 'academic-calender', component: AcademicCalenderComponent},
    {path: 'enquiry', component: EnquiryComponent},
    {path: 'course-co-ordinators', component: CourseCoOrdinatorsComponent},
    {path: 'semester-syllabus', component: SemesterSyllabusComponent},
    {path: 'guidelines', component: GuidelinesComponent},
    {path: 'how-to-get-admission-open', component: HowToGetAdmissionOpenComponent},
    {path: 'why-choose-subharti-university-for-distance-learning', component: WhyChooseSubhartiComponent},
    {path: 'is-subharti-university-good-for-distance-mba', component: IsSubhartiGoodComponent},
    {path: 'examination-schedule', component: ExaminationScheduleComponent},
    {path: 'evalution-procedure', component: EvalutionProcedureComponent},
    {path: 'all-courses', component: AllCoursesComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
