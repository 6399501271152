<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Admission Calender</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Academic
                        Calendar</h4>
                    <style>
                        p {
                            text-align: justify
                        }
                    </style>
                    <div class="rightcategory_box">

                        <table align="left" cellpadding="0" cellspacing="0" width="100%">
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p>
                                        <b>Academic Activity</b></p>
                                </td>
                                <td valign="top">
                                    <p>
                                        <b>Academic Batch </b>
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        <b>Calendar Batch </b>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Notification for Program Announcement &amp; Issue of Application Forms
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        March/April</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        September/October</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Admission
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        July-August</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        January-February
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Dispatch of SLMs
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        August
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        February
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Uploading of Assignments on the Website "www.subhartidde.com"
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        February &amp; Return of Assignment 15th March
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        August &amp; Return of Assignment 15th September
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Last Date of Submission of Examination Form
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        30th April
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        30th October
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Examination
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        June
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        December
                                    </p></td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Announcement / Declaration of Result
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        September
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>
                                        February
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        PCP
                                    </p>
                                </td>
                                <td valign="top">
                                    September-May
                                </td>
                                <td valign="top">
                                    March-November
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                    <style>
                        /*
            Generic Styling, for Desktops/Laptops
            */
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }

                        /* Zebra striping */
                        tr:nth-of-type(odd) {
                            background: #eee;
                        }

                        th {
                            background: #333;
                            color: white;
                            font-weight: bold;
                        }

                        td, th {
                            padding: 6px;
                            border: 1px solid #ccc;
                            text-align: left;
                        }

                        /*
                        Max width before this PARTICULAR table gets nasty
                        This query will take effect for any screen smaller than 760px
                        and also iPads specifically.
                        */
                        @media only screen and (max-width: 760px),
                        (min-device-width: 768px) and (max-device-width: 1024px) {

                            /* Force table to not be like tables anymore */
                            table, thead, tbody, th, td, tr {
                                display: block;
                            }

                            /* Hide table headers (but not display: none;, for accessibility) */
                            thead tr {
                                position: absolute;
                                top: -9999px;
                                left: -9999px;
                            }

                            tr {
                                border: 1px solid #ccc;
                            }

                            td {
                                /* Behave  like a "row" */
                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                padding-left: 50%;
                            }

                            td:before {
                                /* Now like a table header */
                                position: absolute;
                                /* Top/left values mimic padding */
                                top: 6px;
                                left: 6px;
                                width: 45%;
                                padding-right: 10px;
                                white-space: nowrap;
                            }

                            /*
                            Label the data
                            */
                            td:nth-of-type(1):before {
                                content: "First Name";
                            }

                            td:nth-of-type(2):before {
                                content: "Last Name";
                            }

                            td:nth-of-type(3):before {
                                content: "Job Title";
                            }

                            td:nth-of-type(4):before {
                                content: "Favorite Color";
                            }

                            td:nth-of-type(5):before {
                                content: "Wars of Trek?";
                            }

                            td:nth-of-type(6):before {
                                content: "Secret Alias";
                            }

                            td:nth-of-type(7):before {
                                content: "Date of Birth";
                            }

                            td:nth-of-type(8):before {
                                content: "Dream Vacation City";
                            }

                            td:nth-of-type(9):before {
                                content: "GPA";
                            }

                            td:nth-of-type(10):before {
                                content: "Arbitrary Data";
                            }
                        }
                    </style>
                </div>
            </div>
        </div>
    </div>
</div>
