<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Admission Details</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Students
                        Enrolled/Awarded</h4>
                    <style>
                        p {
                            text-align: justify
                        }
                    </style>
                    <div align="center" class="rightcategory_box">
                        <div><strong>Batch 2020-21</strong><br>
                            <br>
                            <table>
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td><strong>UG</strong></td>
                                    <td><strong>PG</strong></td>
                                    <td><strong>Diploma</strong></td>
                                    <td><strong>PG Diploma</strong></td>
                                    <td><strong>Certificate</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Enrolled</strong></td>
                                    <td>2831</td>
                                    <td>657</td>
                                    <td>185</td>
                                    <td>15</td>
                                    <td>7</td>
                                </tr>
                                <tr>
                                    <td><strong>Awarded</strong></td>
                                    <td>5213</td>
                                    <td>2504</td>
                                    <td>28</td>
                                    <td>96</td>
                                    <td>3</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <br>
                            <br>
                            <strong>Batch 2019-20</strong><br>
                            <br>
                            <table>
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td><strong>UG</strong></td>
                                    <td><strong>PG</strong></td>
                                    <td><strong>Diploma</strong></td>
                                    <td><strong>PG Diploma</strong></td>
                                    <td><strong>Certificate</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Enrolled</strong></td>
                                    <td>2821</td>
                                    <td>852</td>
                                    <td>14</td>
                                    <td>117</td>
                                    <td>4</td>
                                </tr>
                                <tr>
                                    <td><strong>Awarded</strong></td>
                                    <td>3546</td>
                                    <td>2304</td>
                                    <td>21</td>
                                    <td>96</td>
                                    <td>2</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <br>
                            <br>
                            <strong>Batch 2018-19</strong><br>
                            <br>
                            <table>
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td><strong>UG</strong></td>
                                    <td><strong>PG</strong></td>
                                    <td><strong>Diploma</strong></td>
                                    <td><strong>PG Diploma</strong></td>
                                    <td><strong>Certificate</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Enrolled</strong></td>
                                    <td>695</td>
                                    <td>284</td>
                                    <td>132</td>
                                    <td>984</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td><strong>Awarded</strong></td>
                                    <td>2587</td>
                                    <td>117</td>
                                    <td>122</td>
                                    <td>957</td>
                                    <td>1</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <style>
                        /*
            Generic Styling, for Desktops/Laptops
            */
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }

                        /* Zebra striping */
                        tr:nth-of-type(odd) {
                            background: #eee;
                        }

                        th {
                            background: #333;
                            color: white;
                            font-weight: bold;
                        }

                        td, th {
                            padding: 6px;
                            border: 1px solid #ccc;
                            text-align: left;
                        }

                        /*
                        Max width before this PARTICULAR table gets nasty
                        This query will take effect for any screen smaller than 760px
                        and also iPads specifically.
                        */
                        @media only screen and (max-width: 760px),
                        (min-device-width: 768px) and (max-device-width: 1024px) {

                            /* Force table to not be like tables anymore */
                            table, thead, tbody, th, td, tr {
                                display: block;
                            }

                            /* Hide table headers (but not display: none;, for accessibility) */
                            thead tr {
                                position: absolute;
                                top: -9999px;
                                left: -9999px;
                            }

                            tr {
                                border: 1px solid #ccc;
                            }

                            td {
                                /* Behave  like a "row" */
                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                padding-left: 50%;
                            }

                            td:before {
                                /* Now like a table header */
                                position: absolute;
                                /* Top/left values mimic padding */
                                top: 6px;
                                left: 6px;
                                width: 45%;
                                padding-right: 10px;
                                white-space: nowrap;
                            }

                            /*
                            Label the data
                            */
                            td:nth-of-type(1):before {
                                content: "First Name";
                            }

                            td:nth-of-type(2):before {
                                content: "Last Name";
                            }

                            td:nth-of-type(3):before {
                                content: "Job Title";
                            }

                            td:nth-of-type(4):before {
                                content: "Favorite Color";
                            }

                            td:nth-of-type(5):before {
                                content: "Wars of Trek?";
                            }

                            td:nth-of-type(6):before {
                                content: "Secret Alias";
                            }

                            td:nth-of-type(7):before {
                                content: "Date of Birth";
                            }

                            td:nth-of-type(8):before {
                                content: "Dream Vacation City";
                            }

                            td:nth-of-type(9):before {
                                content: "GPA";
                            }

                            td:nth-of-type(10):before {
                                content: "Arbitrary Data";
                            }
                        }
                    </style>
                </div>
            </div>
        </div>
    </div>
</div>
