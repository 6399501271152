<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>LIST OF COURSE CO-ORDINATORS</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="container mt-3">
    <h4 style="text-align: center;">List of Course Co-ordinators</h4>
    <!--  <table class="table table-bordered">
          <thead>
          <tr>
              <th>S.No.</th>
              <th>Name of Programme</th>
              <th>Faculty Name</th>
          </tr>
          </thead>
          <tbody>
          <tr>
              <td>1.</td>
              <td>
                  Post Graduate Diploma in Intellectual Property Rights</td>
              <td>Dr. Vaibhav Goel</td>
          </tr>
          <tr>
              <td>2.</td>
              <td>Post Graduate Diploma in Dietetics & Public Nutrition</td>
              <td>Ms. Priyanka Sharma</td>
          </tr>
          <tr>
              <td>3.</td>
              <td>Post Graduate Diploma in Hospital & Health Management</td>
              <td>Dr. Smita Chandna</td>
          </tr>
          <tr>
              <td>4.</td>
              <td>Post Graduate Diploma in Maternal and Child Health</td>
              <td>Dr. Mukti Bhatnagar</td>
          </tr>
          </tbody>-->
    <!--   </table>-->
    <table class="table table-bordered">
        <!--<thead>
        <tr style="text-align: center;">
            <th>S.No.</th>
            <th>Name of Programme</th>
            <th>Faculty Name</th>
        </tr>
        </thead>-->
        <tbody>
        <tbody>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    <strong>S.No.</strong>
                </p>
            </td>
            <td valign="bottom" width="323">
                <p align="center">
                    <strong>Name of Programme</strong>
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Faculty Name </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    1
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Post Graduate Diploma in Intellectual Property Rights
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Vaibhav Goel </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    2
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Post Graduate Diploma in Dietetics &amp; Public Nutrition
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Ms. Priyanka Sharma </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    3
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Post Graduate Diploma in Hospital &amp; Health Management
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Smita Chandna</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    4
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Post Graduate Diploma in Maternal and Child Health
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Mukti Bhatnagar </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    5
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Post Graduate Diploma in Food Safety &amp; Quality Management
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Sunayan</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    6
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Post Graduate Certificate in Patent Practice
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Manoj Tripathi </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    7
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Bachelor of Arts in Journalism &amp; Mass Communications
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Rajesh Kumar </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    8
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Bachelor of Arts
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Neeraj Kumar </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    9
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Bachelor of Commerce
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Ms. Padma Misra</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    10
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Diploma in Business Administration
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Ms. Malika Paul</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    11
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Advance Diploma in Business Administration
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Ms. Mireet Fatima Mir </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    12
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Bachelors in Business Administration
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Rahul Sharma </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    13
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    B. Lib.
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Javed </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    14
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    M. Lib.
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Sudhir Tyagi </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    15
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Master of Arts in English/Hindi/Sociology/Political Science/History/Public Admn.
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Suraj Bhan </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    16
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Master of Arts in Journalism &amp; Mass Communications
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Sailesh Kumar Pandey </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    17
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Master’s in Commerce (M.COM.)
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Ms. Shehnila Athar </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    18
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Master in Business Administration
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Prabhat Kumar </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    19
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    PG Diploma in Computer Applications
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Er. Amit Kumar </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    20
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    M.Sc. Dietetics &amp; Food Service Management
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Ankita Tyagi </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    21
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Diploma in Computer Applications
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Sanjeev Panwar </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    22
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Advance Diploma in Computer Applications
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Ms. Samridhi Sharma </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    23
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Bachelor in Computer Applications
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Shashiraj Teotia </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    24
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Bachelor of Science (PCM)
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Rishabh Raj </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    25
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Bachelor of Science (ZBC)
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Aditya Kumar Sharma </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    26
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    M.Sc. (Computer Applications)
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Mr. Nirankar Sharma </strong>
                </p>
            </td>
        </tr>
        <tr>
            <td nowrap="" valign="bottom" width="64">
                <p align="center">
                    27
                </p>
            </td>
            <td valign="top" width="323">
                <p>
                    Master in Computer Applications
                </p>
            </td>
            <td valign="top" width="147">
                <p align="center">
                    <strong>Dr. Rajesh Kumar Bhagat </strong>
                </p>
            </td>
        </tr>
        </tbody>
    </table>
</div>
