<div class="about-me-area pb-100">
    <div class="container">
        <div class="about-me-inner" style="margin-top: 91px; background-color: #86bc42 !important;">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="about-me-image">
                        <!--<img src="assets/img/business-coach/business-coach2.jpg" alt="business">-->
                        <img src="assets/img/banner/directorate-of-distance-education.png" alt="business">
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="about-me-content">
                        <!-- <span class="sub-title">ABOUT ME</span>-->
                        <h2 class="inter-font">Directorate of Distance Education</h2>
                        <p>In exercise of the powers vested under section 7 (b) of the Act, Swami Vivekanand Subharti
                            University has established the Directorate of Distance Education to help those who want to
                            pursue higher studies, but are unable to do so through regular mode of education due to job
                            constraints, family commitments or remoteness of location.he Distance Education Council of
                            Government of India has accorded recognition to the University for offering programmes
                            through distance education mode also vide letter no. DEC/Recog/2009/3174 dated 09.09.2009,
                            on the recommendation of the Joint Committee of the University Grants Commission,</p>
                        <!--<div class="quote">
                            “I wake up every morning and think to myself, ‘how far can I push this company in the next
                            24 hours.’”
                        </div>-->
                        <a routerLink="/dde" class="default-btn"><i class="flaticon-user"></i>READ MORE<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
