<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Events</li>
            </ul>
            <h2>Events</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="events-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="All">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img1.jpg" alt="image">
                                    </a>
                                    <span class="date">Wed, 20 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Global Conference on Business Management</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img2.jpg" alt="image">
                                    </a>
                                    <span class="date">Tue, 19 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Teacher Education</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img3.jpg" alt="image">
                                    </a>
                                    <span class="date">Mon, 18 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Special Needs Education</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img4.jpg" alt="image">
                                    </a>
                                    <span class="date">Sun, 17 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Literacy Teaching</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img5.jpg" alt="image">
                                    </a>
                                    <span class="date">Sat, 16 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Educational Administration</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Rome, Italy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img6.jpg" alt="image">
                                    </a>
                                    <span class="date">Fri, 15 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Education and Pedagogy</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img7.jpg" alt="image">
                                    </a>
                                    <span class="date">Thu, 14 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Research Conference Aims and Objectives</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Tokyo, Japan</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img8.jpg" alt="image">
                                    </a>
                                    <span class="date">Wed, 13 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Conference on Gender Discrimination in Education</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Oslo, Norway</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img9.jpg" alt="image">
                                    </a>
                                    <span class="date">Tue, 12 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Quality and Improvement in Education Conference</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Tokyo, Japan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Happening">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img1.jpg" alt="image">
                                    </a>
                                    <span class="date">Wed, 20 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Global Conference on Business Management</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img3.jpg" alt="image">
                                    </a>
                                    <span class="date">Mon, 18 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Special Needs Education</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img8.jpg" alt="image">
                                    </a>
                                    <span class="date">Wed, 13 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Conference on Gender Discrimination in Education</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Oslo, Norway</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Upcoming">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img2.jpg" alt="image">
                                    </a>
                                    <span class="date">Tue, 19 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Teacher Education</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img5.jpg" alt="image">
                                    </a>
                                    <span class="date">Sat, 16 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Educational Administration</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Rome, Italy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img7.jpg" alt="image">
                                    </a>
                                    <span class="date">Thu, 14 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Research Conference Aims and Objectives</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Tokyo, Japan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Expired">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img4.jpg" alt="image">
                                    </a>
                                    <span class="date">Sun, 17 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Literacy Teaching</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img6.jpg" alt="image">
                                    </a>
                                    <span class="date">Fri, 15 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">International Conference on Education and Pedagogy</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/single-events" class="d-block">
                                        <img src="assets/img/events/img9.jpg" alt="image">
                                    </a>
                                    <span class="date">Tue, 12 May, 2020</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/single-events">Quality and Improvement in Education Conference</a></h3>
                                    <span class="location"><i class="bx bx-map"></i>Tokyo, Japan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>
