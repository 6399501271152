<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>DIRECTOR'S MESSAGE</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <img src="assets/img/director/director.jpg" alt="image" style="width: 100%">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h3>DIRECTOR'S MESSAGE</h3>
                    <p>Globalization has vanished, the barriers of culture, nationalities and age groups. For a
                        'boundary- free' world, education is the most powerful weapon to develop a country, but with the
                        rising population and increasing cost of living, it is not possible that everyone can reach out
                        to standard quality education by resolving all impossibilities in the field of education. The
                        concept of open and distance learning came in to existence for the same. The underlying
                        philosophy of this concept is the result of the barriers and restrictions that are placed on
                        learners as in the conventional education system. It offers opportunities for part- time study
                        and learning at a distance, for innovation in the curriculum. To allow access to wider section
                        of adult population, to enable students to compensate for lost opportunities in the past or can
                        say to acquire new skills and qualifications for the future. It also redresses social or
                        educational inequality. It is pleasures to state that Swami Vivekanand Subharti University,
                        Meerut, was established with the dream of providing quality education and instilling "Siksha,
                        Seva, Sanskar"avem Rashtriyata" in the youth.</p>
                    <p>Therefore to realise the dream of spreading education & values of 'Subharti' to all, the
                        Directorate of Distance Education was established 2009 with Regular Mode (2008). Learning
                        through Distance Mode proved out to be a ray of hope and opened new directions of opportunities
                        for students to obtain higher education without the constraints of geographies and time.
                        Directorate of Distance Education (DDE), Swami Vivekananda Subharti University offers a chance
                        to enhance the career. Our mission is self-paced learning and to provide ultimate convenience,
                        ease and flexibility to our students. We seek self-motivated individuals who believe in charting
                        their own methods of learning, who take full advantage of the opportunities, and are willing to
                        contribute their best.</p>
                    <p>As the Director of Directorate of Distance Education of this prestigious University, it is my
                        accountability and responsibility to reach out to maximum students with the aim to increase the
                        country GER (Gross enrolment Ratio) & spread education till the smallest & darkest of corners.
                        I, along with my team, have pledge to set new standards for education to spread the values of
                        the founders of 'Subharti' far and wide and to contribute in building up of a strongest
                        nation.</p>
                    <p>"I welcome each one of you to be a part of the 'Subharti University' where Subharti Online
                        programmes have been designed with the objective of giving a perfect boost to the career of
                        learners and also to help them in using their full potential for elevation, by bringing together
                        innovation pedagogy, rich content and latest technology, Subharti Online programmes are the home
                        to a range of University Grants Commission (UGC) entitled courses meant for anytime, anywhere
                        learning, Innovative teaching methodologies and quality education are blended together to create
                        unique learning experiences.". These online courses serve the educational aspirations of
                        students across globe through 22 programmes offered under Masters and bachelors degree
                        programmes.  The well researched curriculum, renowned faculty, cutting edge technology is some
                        of the noteworthy features.</p>
                    <p>Miles to go before I sleep</p>
                    <p style="float: end; color: black">Dr. Santosh Sharma<br>Director</p>
                </div>
            </div>
        </div>
    </div>
</div>
