<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>NEW SYLLABUS SEMESTER PROGRAMME (2020-21)</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area ptb-100 bg-f5f7fa">
    <div class="container">
        <div class="row">
            <!-- <div class="col-12 text-center">
                 <h3>NEW SYLLABUS SEMESTER PROGRAMME (2020-21)</h3>
             </div>-->
        </div>
        <div class="row">
            <div class="about-text-container">
                <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">New Syllabus Semester
                    Programme (2020-21)</h4>
                <style>
                    p {
                        text-align: justify
                    }
                </style>
                <div class="rightcategory_box">
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/BA.pdf"
                                   style="font-size:18px; font-weight:400">BA </a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/B.COM(H).pdf"
                                   style="font-size:18px; font-weight:400">B.COM
                                    (H)</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/B.COM.pdf"
                                   style="font-size:18px; font-weight:400">B.COM</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/BBA.pdf"
                                   style="font-size:18px; font-weight:400">BBA </a>
                            </h6>

                        </div>
                    </div>

                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/BLIB.pdf"
                                   style="font-size:18px; font-weight:400">BLIB</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/M.COM.pdf"
                                   style="font-size:18px; font-weight:400">M.COM</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA-EDUCATION.pdf"
                                   style="font-size:18px; font-weight:400">MA-EDUCATION</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA-ENGLISH.pdf"
                                   style="font-size:18px; font-weight:400">MA-ENGLISH</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA-HINDI.pdf"
                                   style="font-size:18px; font-weight:400">MA-HINDI</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA-HISTORY.pdf"
                                   style="font-size:18px; font-weight:400">MA-HISTORY</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="documents/syllabus202021/MA-JMC.pdf" style="font-size:18px; font-weight:400">MA-JMC</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA-JMC.pdf"
                                   style="font-size:18px; font-weight:400">MA-POLITICAL SCIENCE</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA-PUBLICA%20ADMINISTRATION.pdf"
                                   style="font-size:18px; font-weight:400">MA-PUBLICA ADMINISTRATION</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA-SOCIOLOGY.pdf"
                                   style="font-size:18px; font-weight:400">MA-SOCIOLOGY</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA%20in%20Mathematics.pdf"
                                   style="font-size:18px; font-weight:400">MA in Mathematics</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA%20in%20Buddhist.pdf"
                                   style="font-size:18px; font-weight:400">MA in Buddhist</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA%20in%20Home%20Science.pdf"
                                   style="font-size:18px; font-weight:400">MA in Home Science</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MBA.pdf"
                                   style="font-size:18px; font-weight:400">MBA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MLIB.pdf"
                                   style="font-size:18px; font-weight:400">MLIB</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MA%20in%20Economics.pdf"
                                   style="font-size:18px; font-weight:400">M.A. in Economics</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/ADBA.pdf"
                                   style="font-size:18px; font-weight:400">ADBA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/ADCA.pdf"
                                   style="font-size:18px; font-weight:400">ADCA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/BA%20_Fashion%20Design_.pdf"
                                   style="font-size:18px; font-weight:400">BA Fashion Design</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/BA%20_Fashion%20Design_.pdf"
                                   style="font-size:18px; font-weight:400">BA FINE ARTS</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/BCA.pdf"
                                   style="font-size:18px; font-weight:400">BCA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/DBA.pdf"
                                   style="font-size:18px; font-weight:400">DBA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/DCA.pdf"
                                   style="font-size:18px; font-weight:400">DCA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/M.Sc(CA).pdf"
                                   style="font-size:18px; font-weight:400">M.Sc(CA)</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/M.Sc(DFSM).pdf"
                                   style="font-size:18px; font-weight:400">M.Sc (DFSM)</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MCA.pdf"
                                   style="font-size:18px; font-weight:400">MCA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/Post%20Graduate%20Diploma%20in%20Dietetics%20&%20Public%20Nutrition.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Dietetics &amp;
                                    Public Nutrition</a>
                            </h6>

                        </div>
                    </div>

                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/Post%20Graduate%20Diploma%20in%20Food%20Safety%20&%20Quality%20Management.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Food Safety &amp;
                                    Quality Management</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/Post%20Graduate%20Diploma%20in%20Hospital%20&%20Health%20Management.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Hospital &amp;
                                    Health Management</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/Post%20Graduate%20Diploma%20in%20Intellectual%20Property%20Rights.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Intellectual
                                    Property Rights</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/Post%20Graduate%20Diploma%20in%20Maternal%20&%20Child%20Health.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Maternal &amp; Child
                                    Health</a>
                            </h6>

                        </div>
                    </div>

                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/Post%20Graduate%20Diploma%20in%20Patent%20Practice.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Patent Practice</a>
                            </h6>

                        </div>
                    </div>

                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="https://subhartidde.com/documents/syllabus202021/MBA-HOSPITAL%20ADMINISTRATION.pdf"
                                   style="font-size:18px; font-weight:400">MBA-HOSPITAL ADMINISTRATION</a>
                            </h6>

                        </div>
                    </div>


                    <!--<div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Advanced Diploma in Computer Application.pdf" style="font-size:18px; font-weight:400">	Advanced Diploma in Computer Application	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACELOR OF COMMERCE.pdf" style="font-size:18px; font-weight:400">	BACELOR OF COMMERCE	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF ARTS(Fashion Design).pdf" style="font-size:18px; font-weight:400">	BACHELOR OF ARTS(Fashion Design)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF ARTS(FINE ARTS).pdf" style="font-size:18px; font-weight:400">	BACHELOR OF ARTS(FINE ARTS)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF ARTS(JMC).pdf" style="font-size:18px; font-weight:400">	BACHELOR OF ARTS(JMC)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF ARTS.pdf" style="font-size:18px; font-weight:400">	BACHELOR OF ARTS	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF BUSINESS ADMINISTRATION.pdf" style="font-size:18px; font-weight:400">	BACHELOR OF BUSINESS ADMINISTRATION	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF COMPUTER APPLICATION.pdf" style="font-size:18px; font-weight:400">	BACHELOR OF COMPUTER APPLICATION	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Bachelor of Library Science.pdf" style="font-size:18px; font-weight:400">	Bachelor of Library Science	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF SCIENCE(PCM).pdf" style="font-size:18px; font-weight:400">	BACHELOR OF SCIENCE(PCM)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	BACHELOR OF SCIENCE(ZBC).pdf" style="font-size:18px; font-weight:400">	BACHELOR OF SCIENCE(ZBC)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Diploma in Business Administration.pdf" style="font-size:18px; font-weight:400">	Diploma in Business Administration	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Diploma in Computer Application.pdf" style="font-size:18px; font-weight:400">	Diploma in Computer Application	</a>
                                                </h6>

                                        </div>
                                    </div>

                                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/MCA.pdf" style="font-size:18px; font-weight:400">	MASTER OF COMPUTER APPLICATION	</a>
                                                </h6>

                                        </div>
                                    </div>


                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	MASTER OF ARTS(EDUCATION).pdf" style="font-size:18px; font-weight:400">	MASTER OF ARTS(EDUCATION)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	MASTER OF BUSINESS ADMINISTRATION.pdf" style="font-size:18px; font-weight:400">	MASTER OF BUSINESS ADMINISTRATION	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	MASTER OF COMMERCE.pdf" style="font-size:18px; font-weight:400">	MASTER OF COMMERCE	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Master of Library Science.pdf" style="font-size:18px; font-weight:400">	Master of Library Science	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	MASTER OF SCIENCE(CA).pdf" style="font-size:18px; font-weight:400">	MASTER OF SCIENCE(CA)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	MASTER OF SCIENCE(DFSM).pdf" style="font-size:18px; font-weight:400">	MASTER OF SCIENCE(DFSM)	</a>
                                                </h6>

                                        </div>
                                    </div>
                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	MASTER OFARTS(JMC).pdf" style="font-size:18px; font-weight:400">	MASTER OFARTS(JMC)	</a>
                                                </h6>

                                        </div>
                                    </div>

                                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/MASTER OF ARTS.pdf" style="font-size:18px; font-weight:400">	MASTER OF ARTS	</a>
                                                </h6>

                                        </div>
                                    </div>

                                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/MBA-HOSPITAL ADMINISTRATION.pdf" style="font-size:18px; font-weight:400">	MBA HOSPITAL ADMINISTRATION	</a>
                                                </h6>

                                        </div>
                                    </div>

                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Post Graduate Diploma in Computer Application.pdf" style="font-size:18px; font-weight:400">	Post Graduate Diploma in Computer Application	</a>
                                                </h6>

                                        </div>
                                    </div>
                                <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Post Graduate Diploma in Dietetics & Public Nutrition.pdf" style="font-size:18px; font-weight:400">	Post Graduate Diploma in Dietetics & Public Nutrition	</a>
                                                </h6>

                                        </div>
                                    </div>
                                <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Post Graduate Diploma in Food Safety & Quality Management.pdf" style="font-size:18px; font-weight:400">	Post Graduate Diploma in Food Safety & Quality Management	</a>
                                                </h6>

                                        </div>
                                    </div>
                                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Post Graduate Diploma in Hospital & Health Management.pdf" style="font-size:18px; font-weight:400">	Post Graduate Diploma in Hospital & Health Management	</a>
                                                </h6>

                                        </div>
                                    </div>
                                    <div class="blog_box">
                                        <div class="blog_heading">
                                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                            <a href="documents/syllabus202021/	Post Graduate Diploma in Intellectual Property Rights.pdf" style="font-size:18px; font-weight:400">	Post Graduate Diploma in Intellectual Property Rights	</a>
                                                </h6>

                                        </div>
                                    </div>
                                <div class="blog_box">
                                                    <div class="blog_heading">
                                                        <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                                        <a href="documents/syllabus202021/	Post Graduate Diploma in Maternal & Child Health.pdf" style="font-size:18px; font-weight:400">	Post Graduate Diploma in Maternal & Child Health	</a>
                                                            </h6>

                                                    </div>
                                                </div> -->
                    <br class="clear">
                </div>
                <style>
                    /*
        Generic Styling, for Desktops/Laptops
        */
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }

                    /* Zebra striping */
                    tr:nth-of-type(odd) {
                        background: #eee;
                    }

                    th {
                        background: #333;
                        color: white;
                        font-weight: bold;
                    }

                    td, th {
                        padding: 6px;
                        border: 1px solid #ccc;
                        text-align: left;
                    }

                    /*
                    Max width before this PARTICULAR table gets nasty
                    This query will take effect for any screen smaller than 760px
                    and also iPads specifically.
                    */
                    @media only screen and (max-width: 760px),
                    (min-device-width: 768px) and (max-device-width: 1024px) {

                        /* Force table to not be like tables anymore */
                        table, thead, tbody, th, td, tr {
                            display: block;
                        }

                        /* Hide table headers (but not display: none;, for accessibility) */
                        thead tr {
                            position: absolute;
                            top: -9999px;
                            left: -9999px;
                        }

                        tr {
                            border: 1px solid #ccc;
                        }

                        td {
                            /* Behave  like a "row" */
                            border: none;
                            border-bottom: 1px solid #eee;
                            position: relative;
                            padding-left: 50%;
                        }

                        td:before {
                            /* Now like a table header */
                            position: absolute;
                            /* Top/left values mimic padding */
                            top: 6px;
                            left: 6px;
                            width: 45%;
                            padding-right: 10px;
                            white-space: nowrap;
                        }

                        /*
                        Label the data
                        */
                        td:nth-of-type(1):before {
                            content: "First Name";
                        }

                        td:nth-of-type(2):before {
                            content: "Last Name";
                        }

                        td:nth-of-type(3):before {
                            content: "Job Title";
                        }

                        td:nth-of-type(4):before {
                            content: "Favorite Color";
                        }

                        td:nth-of-type(5):before {
                            content: "Wars of Trek?";
                        }

                        td:nth-of-type(6):before {
                            content: "Secret Alias";
                        }

                        td:nth-of-type(7):before {
                            content: "Date of Birth";
                        }

                        td:nth-of-type(8):before {
                            content: "Dream Vacation City";
                        }

                        td:nth-of-type(9):before {
                            content: "GPA";
                        }

                        td:nth-of-type(10):before {
                            content: "Arbitrary Data";
                        }
                    }
                </style>
            </div>
        </div>
    </div>
</div>
