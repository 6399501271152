<!--
<div class="banner-wrapper" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-wrapper-text">
                    &lt;!&ndash;<h1>Build Skills With Experts Any Time, Anywhere</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>&ndash;&gt;
                    &lt;!&ndash;<a routerLink="/courses-4" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>&ndash;&gt;
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape11"><img src="assets/img/banner-shape12.png" alt="image"></div>
    <div class="banner-shape12"><img src="assets/img/banner-shape13.png" alt="image"></div>
    <div class="banner-shape13"><img src="assets/img/banner-shape14.png" alt="image"></div>
</div>
-->

<div class="banner-wrapper">
    <div class="kitchen-feedback-slides">
        <owl-carousel-o [options]="kitchenFeedbackSlides">
            <ng-template carouselSlide>
                <div class="single-kitchen-feedback-box">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/banner/main-banner_1.jpeg" alt="user">
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-kitchen-feedback-box">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/banner/main-banner_2.jpeg" alt="user">
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>


<!--
<div class="banner-wrapper" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-wrapper-text">
                    &lt;!&ndash;<h1>Build Skills With Experts Any Time, Anywhere</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>&ndash;&gt;
                    &lt;!&ndash;<a routerLink="/courses-4" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>&ndash;&gt;
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape11"><img src="assets/img/banner-shape12.png" alt="image"></div>
    <div class="banner-shape12"><img src="assets/img/banner-shape13.png" alt="image"></div>
    <div class="banner-shape13"><img src="assets/img/banner-shape14.png" alt="image"></div>
</div>
-->

<!--<div class="banner-wrapper">
    <div class="kitchen-feedback-slides">
        <owl-carousel-o [options]="kitchenFeedbackSlides" *ngIf="mainBannerSliders && mainBannerSliders.length > 0">
            <ng-template carouselSlide *ngFor="let mainBannerSlider of mainBannerSliders">
                <div class="single-kitchen-feedback-box">
                    <div class="client-info d-flex align-items-center"
                         style="background-image: url({{mainBannerSlider.img}}); height:600px;">
                        &lt;!&ndash;<h1 class="color-white">
                            <span style="color: white;">
                            Online Distance Education
                        </span>
                        </h1>&ndash;&gt;
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>-->
<!--<div class="kitchenFeedbackSlides">
    <div class="kitchen-feedback-slides">
    <owl-carousel-o [options]="kitchenFeedbackSlides" *ngIf="mainBannerSliders && mainBannerSliders.length > 0">
        <ng-template carouselSlide *ngFor="let mainBannerSlider of mainBannerSliders">
            <div class="banner-wrapper">
                <div class="container-fluid">
                    <div class="main-banner-content text-center"
                         style="background-image: url({{mainBannerSlider.img}});" style="height: 500px; width: 100%;">
                        <h1 class="color-white"><span><span
                            style="color: #170478; font-weight: 600"
                            class="animate-charcter">Online</span> & <span
                            style="color: #bd081c; font-weight: 600" class="animate-charcter">Distance</span> Education</span>
                        </h1>
                        &lt;!&ndash;<img src="{{mainBannerSlider?.img}}">&ndash;&gt;
                        &lt;!&ndash;<p>{{mainBannerSlider?.info}}</p>&ndash;&gt;
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
    </div>
</div>-->




