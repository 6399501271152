<div class="health-services-area bg-fcf7f3 pt-100 pb-70" *ngFor="let Image of bgImage;">
    <div class="container">
        <div class="section-title">
            <!--<span class="sub-title">UNIVERSITY TOP FACILITIES</span>-->
            <h2 class="playfair-display-font">UNIVERSITY TOP FACILITIES</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
         <!--   <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="fa fa-building" aria-hidden="true"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">250 Acre Campus</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Finest Faculties</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-lotus"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Wi-Fi Campus</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-tomato"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Residential Facilities</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga-1"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Banking Services</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Transport Facilities</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Ragging Free Campus</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1"> World Class Infrastructure</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Air Conditioned Hostels</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Medicare Facilities</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Canteens & Cafeterias</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Gymnasiums</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>-->
            <div class="kitchen-feedback-area pb-70">
                <div class="container">
                    <h3> Latest News</h3>
                    <div class="kitchen-feedback-slides">
                        <owl-carousel-o [options]="kitchenFeedbackSlides">
                            <ng-template carouselSlide>
                                <div class="single-kitchen-feedback-box">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user/user7.png" alt="user">
                                        <div class="title">
                                            <h3>John Smith</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-kitchen-feedback-box">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user/user8.png" alt="user">
                                        <div class="title">
                                            <h3>John Smith</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-kitchen-feedback-box">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user/user9.png" alt="user">
                                        <div class="title">
                                            <h3>John Smith</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
                <!--<div class="container pt-70">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                            <div class="funfacts-box">
                                <div class="content">
                                    <h3><span class="odometer" [countUp]="3279">00</span></h3>
                                    <p>Enrolled Learners</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                            <div class="funfacts-box">
                                <div class="content">
                                    <h3><span class="odometer" [countUp]="250">00</span></h3>
                                    <p>Online Instructors</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                            <div class="funfacts-box">
                                <div class="content">
                                    <h3><span class="odometer" [countUp]="1926">00</span></h3>
                                    <p>Finished Sessions</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                            <div class="funfacts-box">
                                <div class="content">
                                    <h3><span class="odometer" [countUp]="100">00</span>%</h3>
                                    <p>SATISFACTION RATE</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>

        </div>
    </div>
</div>
