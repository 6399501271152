<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Detail Syllabus</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Detail Syllabus</h4>
                    <style>
                        p {
                            text-align: justify
                        }

                        h6 {
                            font-size: 24px;
                            text-align: center;
                            background: #CCCCCC;
                            padding: 10px
                        }

                        h2 {
                            font-size: 18px;
                            text-align: center;
                            margin-bottom: 10px;
                            color: red
                        }
                    </style>
                    <div class="rightcategory_box">
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    ADBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/ADBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    ADCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/ADCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    B.Com
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/B.COM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    B.Lib
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/B.lib.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BSc (PCM)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/B.SC(PCM).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BSc (ZBC)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/BSc(ZBC).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/BA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/BBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BBA-HM
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/BBA-HM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/BCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BA (JMC)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/BJMC.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    DBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/DBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    DCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/DCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (English)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MA(English).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Hindi)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MA(Hindi).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (History)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MA(History).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Pol. Science)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MA(PoliticalScience).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Public Admin)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MA(PublicAdministration).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Sociology)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MA(Sociology).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (JMC)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MA(JMC).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    M. Lib
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/M.lib.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MSc (CA)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MSc(CA).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MSc (DFSM)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/M.Sc(DFSM).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MCom
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/MCom.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>

                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/PGDCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDFSQM
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/PGDFSQM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDHHM
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/PGDHHM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDIPR
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/PGDIPR.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDPPN
                                </h6>
                                <h2 align="center">
                                    <a href="assets/img/pdf/PGDPPN.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>


                    </div>
                    <style>
                        /*
                Generic Styling, for Desktops/Laptops
                */
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }

                        /* Zebra striping */
                        tr:nth-of-type(odd) {
                            background: #eee;
                        }

                        th {
                            background: #333;
                            color: white;
                            font-weight: bold;
                        }

                        td, th {
                            padding: 6px;
                            border: 1px solid #ccc;
                            text-align: left;
                        }

                        /*
                        Max width before this PARTICULAR table gets nasty
                        This query will take effect for any screen smaller than 760px
                        and also iPads specifically.
                        */
                        @media only screen and (max-width: 760px),
                        (min-device-width: 768px) and (max-device-width: 1024px) {

                            /* Force table to not be like tables anymore */
                            table, thead, tbody, th, td, tr {
                                display: block;
                            }

                            /* Hide table headers (but not display: none;, for accessibility) */
                            thead tr {
                                position: absolute;
                                top: -9999px;
                                left: -9999px;
                            }

                            tr {
                                border: 1px solid #ccc;
                            }

                            td {
                                /* Behave  like a "row" */
                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                padding-left: 50%;
                            }

                            td:before {
                                /* Now like a table header */
                                position: absolute;
                                /* Top/left values mimic padding */
                                top: 6px;
                                left: 6px;
                                width: 45%;
                                padding-right: 10px;
                                white-space: nowrap;
                            }

                            /*
                            Label the data
                            */
                            td:nth-of-type(1):before {
                                content: "First Name";
                            }

                            td:nth-of-type(2):before {
                                content: "Last Name";
                            }

                            td:nth-of-type(3):before {
                                content: "Job Title";
                            }

                            td:nth-of-type(4):before {
                                content: "Favorite Color";
                            }

                            td:nth-of-type(5):before {
                                content: "Wars of Trek?";
                            }

                            td:nth-of-type(6):before {
                                content: "Secret Alias";
                            }

                            td:nth-of-type(7):before {
                                content: "Date of Birth";
                            }

                            td:nth-of-type(8):before {
                                content: "Dream Vacation City";
                            }

                            td:nth-of-type(9):before {
                                content: "GPA";
                            }

                            td:nth-of-type(10):before {
                                content: "Arbitrary Data";
                            }
                        }
                    </style>
                </div>
            </div>
        </div>
    </div>
</div>
