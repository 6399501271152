<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>EXAMINATION SCHEDULE</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <!--<div class="blog-details-desc">
                    <div class="article-content">
                        <h3>EXAMINATION SCHEDULE</h3>
                        <p>In exercise of the powers vested under section 7 (b) of the Act, Swami Vivekanand Subharti
                            University has established the Directorate of Distance Education to help those who want to
                            pursue higher studies, but are unable to do so through regular mode of education due to job
                            constraints, family commitments or remoteness of location.</p>
                        <p>The Distance Education Council of Government of India has accorded recognition to the
                            University for offering programmes through distance education mode also vide letter no.
                            DEC/Recog/2009/3174 dated 09.09.2009, on the recommendation of the Joint Committee of the
                            University Grants Commission, All India Council for Technical Education and Distance
                            Education Council. Accordingly, the University has started Distance Education programmes at
                            undergraduate and post-graduate levels with effect from the academic year 2009-10 in various
                            disciplines.</p>
                        <p>As per UGC letter no. F.9.37/2008(CPP-1) dated 06.02.2009, Swami Vivekanand Subharti
                            University, being a State approved private University u/s 2(f) of UGC Act 1956, is competent
                            to award degrees specified in section 22 of the UGC Act 1956. The programmes conducted by
                            the University through distance education are identical in content to the courses offered
                            through the normal mode. Consequently, as per Government of India Gazette Notification No.
                            44, F.No.18-15/93-TD.V/TS-IV dated 01.03.1995, the degrees awarded by the University through
                            distance education stand automatically recognized for all purposes, including employment to
                            posts and services under the Central Government.</p>
                    </div>
                </div>-->
                <div class="about-text-container">
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Back Paper Date
                        Sheet </h4>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/BACK%20PAPER%20FIRST%20SEMESTOR%20EXAMINATION%20DATE%20SHEET.pdf">BACK
                        PAPER FIRST SEMESTOR EXAMINATION DATE SHEET</a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/BACK%20PAPER%20SECOND%20SEMESTOR%20EXAMINATION%20DATE%20SHEET.pdf">BACK
                        PAPER SECOND SEMESTOR EXAMINATION DATE SHEET</a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/BACK%20PAPER%20THIRD%20SEMESTOR%20EXAMINATION%20DATE%20SHEET.pdf">BACK
                        PAPER THIRD SEMESTOR EXAMINATION DATE SHEET</a></p>

                    <!-- <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">CTM Paper Date Sheet</h4>

                   <p><a href="documents/date-sheet/CTM PAPER DATE SHEET.pdf" class="examsc_a">CTM EXAMINATION DATE SHEET</a></p>-->
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Practical Date
                        Sheet </h4>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/PRACTICAL%20DATE%20SHEET%20OF%20ACADEMIC%20BATCH%20(A-2020-21).pdf"
                        class="examsc_a">PRACTICAL DATE SHEET OF ACADEMIC BATCH (A-2020-21)</a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/PRACTICAL%20DATE%20SHEET%20OF%20ACADEMIC%20BATCH%20(A-2021-22).pdf"
                        class="examsc_a">PRACTICAL DATE SHEET OF ACADEMIC BATCH (A-2021-22)</a></p>

                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/PRACTICAL%20DATE%20SHEET%20OF%20CALENDAR%20BATCH%202020.pdf"
                        class="examsc_a">PRACTICAL DATE SHEET OF CALENDAR BATCH 2020</a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/PRACTICAL%20DATE%20SHEET%20OF%20CALENDAR%20BATCH%202021.pdf"
                        class="examsc_a">PRACTICAL DATE SHEET OF CALENDAR BATCH 2021</a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/PRACTICAL%20DATE%20SHEET%20OF%20CALENDAR%20BATCH%202022.pdf"
                        class="examsc_a">PRACTICAL DATE SHEET OF CALENDAR BATCH 2022</a></p>

                    <!--<p><a href="documents/date-sheet/FIRST YEAR BACK PAPER DATE SHEET.pdf">First Year Back Paper Date Sheet </a></p>
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Practical Date Sheet </h4>


<p><a href="documents/date-sheet/PRACTICAL DATE SHEET OF THIRD YEAR.pdf" class="examsc_a">PRACTICAL EXAMINATION SCHEDULE CALENDAR BATCH 2019 FOR 3rd YEAR STUDENTS</a></p>	-->

                    <!--<p><a href="dde_files/examination/PRACTICAL DATE SHEET OF ACADEMIC BATCH SECOND SEMESTER(A-2020-21).pdf" class="examsc_a">PRACTICAL DATE SHEET OF ACADEMIC BATCH SECOND SEMESTER(A-2020-21)</a><br>
                    <p><a href="dde_files/examination/PRACTICAL DATE SHEET OF CALENDAR BATCH FIRST SEMESTER(C-2021).pdf" class="examsc_a">PRACTICAL DATE SHEET OF CALENDAR BATCH FIRST SEMESTER(C-2021)</a><br>
                    <p><a href="dde_files/examination/PRACTICAL DATE SHEET OF CALENDAR BATCH THIRD SEMESTER(C-2020).pdf" class="examsc_a">PRACTICAL DATE SHEET OF CALENDAR BATCH THIRD SEMESTER(C-2020)</a><br>

<p><a href="documents/date-sheet/FIRST YEAR BACK PAPER PRACTICAL DATE SHEET.pdf" class="examsc_a">First Year Back Paper Practical Date Sheet</a><br>

                                               <a href="documents/date-sheet/SECOND YEAR PRACTICAL DATE SHEET OF ACADEMIC BATCH-2020.pdf" class="examsc_a">Second Year Practical Date Sheet Of Academic Batch-2020</a></p>
                    <p><a href="documents/date-sheet/THIRD YEAR PRACTICAL DATE SHEET OF ACADEMIC BATCH-2019.pdf" class="examsc_a">Third Year Practical Date Sheet Of Academic Batch-2019</a>
                         <br>
                      <br>
                                              </p>-->
                    <h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Date Sheet of
                        Acadamic Batch</h4>
                    <h4 style="background: #2d3e50;color: white;padding: 11px; margin-bottom:10px">Examination
                        Schedule</h4>

                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/MAIN%20EXAMINATION%20FIFTH%20SEMESTOR%20DATE%20SHEET%20OF%20CALENDAR-2020.pdf">MAIN
                        EXAMINATION FIFTH SEMESTOR DATE SHEET OF CALENDAR-2020</a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/MAIN%20EXAMINATION%20FIRST%20SEMESTOR%20DATE%20SHEET%20OF%20CALENDAR-2022.pdf"
                        class="examsc_a">MAIN EXAMINATION FIRST SEMESTOR DATE SHEET OF CALENDAR-2022
                    </a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/MAIN%20EXAMINATION%20FOURTH%20SEMESTOR%20DATE%20SHEET%20OF%20ACADEMIC-2020-21.pdf"
                        class="examsc_a">MAIN EXAMINATION FOURTH SEMESTOR DATE SHEET OF ACADEMIC-2020-21</a></p>
                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/MAIN%20EXAMINATION%20SECOND%20SEMESTOR%20DATE%20SHEET%20OF%20ACADEMIC-2021-22.pdf"
                        class="examsc_a">MAIN EXAMINATION SECOND SEMESTOR DATE SHEET OF ACADEMIC-2021-22</a></p>

                    <p><a
                        href="https://subhartidde.com/documents/date-sheet/Aug2022/MAIN%20EXAMINATION%20THIRD%20SEMESTOR%20DATE%20SHEET%20OF%20CALENDAR-2021.pdf"
                        class="examsc_a">MAIN EXAMINATION THIRD SEMESTOR DATE SHEET OF CALENDAR-2021</a></p>
                    <!--<p><a href="dde_files/examination/EXAMINATION DATE SHEET OF ACADEMIC BATCH SECOND SEMESTER(A-2020-21).pdf" class="examsc_a">EXAMINATION DATE SHEET OF ACADEMIC BATCH SECOND SEMESTER(A-2020-21)</a>

                    <p><a href="dde_files/examination/EXAMINATION DATE SHEET OF CALENDAR BATCH FIRST SEMESTER(C-2021).pdf" class="examsc_a">EXAMINATION DATE SHEET OF CALENDAR BATCH FIRST SEMESTER(C-2021)</a>

                    <p><a href="dde_files/examination/EXAMINATION DATE SHEET OF CALENDAR BATCH THIRD SEMESTER(C-2020).pdf" class="examsc_a">EXAMINATION DATE SHEET OF CALENDAR BATCH THIRD SEMESTER(C-2020)</a>


                     <p><a href="documents/date-sheet/SECOND YEAR DATE SHEET OF ACADEMIC BATCH-2020.pdf" class="examsc_a">Second Year Date Sheet of A-2019-20</a>


                    <p><a href="documents/date-sheet/SECOND YEAR DATE SHEET OF ACADEMIC BATCH-2020.pdf" class="examsc_a">Second Year Date Sheet of A-2019-20</a>
                    <p><a href="documents/date-sheet/THIRD YEAR DATE SHEET OF ACADEMIC BATCH-2019.pdf" class="examsc_a">Third Year Date Sheet of A-2018-19</a>				                                            </p>  -->

                    <!--  <h4 style="background: #2d3e50;color: white;padding: 11px; margin-bottom:10px">TERM END PRACTICAL EXAMINATION SCHEDULE ACADEMIC BATCH 2020-21</h4>
<h4 style="background: #86bc42;color: white;padding: 21px; margin-bottom:15px">Revised Tentative Examination Schedule for December 2020</h4>

<style>
p { text-align:justify }
</style>

<h4 style="background: #2d3e50;color: white;padding: 11px; margin-bottom:10px">CTM Examination Schedule</h4>
<a href="documents/CTM_Z11_2.pdf" class="examsc_a">Download</a>

<h4 style="background: #2d3e50;color: white;padding: 11px; margin-bottom:10px; margin-top:20px">Practical Examination Schedule</h4>
<a href="documents/PR_Z11_12.pdf" class="examsc_a">1st Year (Back Paper)</a>
<br>
<a href="documents/PR_Z11_22.pdf" class="examsc_a">2nd Year (Main/Back Paper)</a>
<br>
<a href="documents/PR_Z11_32.pdf" class="examsc_a">3rd Year (Main/Back Paper)</a>

<h4 style="background: #2d3e50;color: white;padding: 11px; margin-bottom:10px; margin-top:20px">Main/Back Paper-Examaintion Schedule</h4>
<a href="documents/TH_Z11_12.pdf" class="examsc_a">Back Paper-1st Year</a>
             <br>
                  <a href="documents/TH_Z11_22.pdf" class="examsc_a">Main/Back Paper-2nd Year</a>
             <br>
                  <a href="documents/TH_Z11_32.pdf" class="examsc_a">Main/Back Paper-3rd Year</a>

<br>    -->
                    <style>
                        /*
            Generic Styling, for Desktops/Laptops
            */
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }

                        /* Zebra striping */
                        tr:nth-of-type(odd) {
                            background: #eee;
                        }

                        th {
                            background: #333;
                            color: white;
                            font-weight: bold;
                        }

                        td, th {
                            padding: 6px;
                            border: 1px solid #ccc;
                            text-align: left;
                        }

                        /*
                        Max width before this PARTICULAR table gets nasty
                        This query will take effect for any screen smaller than 760px
                        and also iPads specifically.
                        */
                        @media only screen and (max-width: 760px),
                        (min-device-width: 768px) and (max-device-width: 1024px) {

                            /* Force table to not be like tables anymore */
                            table, thead, tbody, th, td, tr {
                                display: block;
                            }

                            /* Hide table headers (but not display: none;, for accessibility) */
                            thead tr {
                                position: absolute;
                                top: -9999px;
                                left: -9999px;
                            }

                            tr {
                                border: 1px solid #ccc;
                            }

                            td {
                                /* Behave  like a "row" */
                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                padding-left: 50%;
                            }

                            td:before {
                                /* Now like a table header */
                                position: absolute;
                                /* Top/left values mimic padding */
                                top: 6px;
                                left: 6px;
                                width: 45%;
                                padding-right: 10px;
                                white-space: nowrap;
                            }

                            /*
                            Label the data
                            */
                            td:nth-of-type(1):before {
                                content: "First Name";
                            }

                            td:nth-of-type(2):before {
                                content: "Last Name";
                            }

                            td:nth-of-type(3):before {
                                content: "Job Title";
                            }

                            td:nth-of-type(4):before {
                                content: "Favorite Color";
                            }

                            td:nth-of-type(5):before {
                                content: "Wars of Trek?";
                            }

                            td:nth-of-type(6):before {
                                content: "Secret Alias";
                            }

                            td:nth-of-type(7):before {
                                content: "Date of Birth";
                            }

                            td:nth-of-type(8):before {
                                content: "Dream Vacation City";
                            }

                            td:nth-of-type(9):before {
                                content: "GPA";
                            }

                            td:nth-of-type(10):before {
                                content: "Arbitrary Data";
                            }
                        }
                    </style>
                    <p></p>

                </div>
            </div>
        </div>
    </div>
</div>
