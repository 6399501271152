<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>How to get admission in Subharti University?</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>How to get admission in Subharti University?</h3>
                        <p>In 2008, Swami Vivekananda Subharti University was founded in Meerut, Uttar Pradesh. Distance
                            learning programmes offered by the institution have been authorised by the UGC's Distance
                            Education Bureau and the All India Council for Technical Education (AICTE). An "A" was
                            awarded by NAAC to the institution in 2006.</p>
                        <p>All levels of education from undergraduate to postgraduate as well as diploma and certificate
                            programmes are available at Swami Vivekananda Subharti University.
                        </p>
                        <ul>
                            <li>
                                <P>A minimum of 45% in a combination of Physics, Mathematics, and one other science
                                    (Biology, Chemistry, or Computer Science) is required for admission to the Bachelor
                                    of Technology programme.</P>
                            </li>
                            <li>
                                <p>A three-year Engineering Diploma or a Bachelor of Science with Mathematics as a
                                    required major are prerequisites for B.Tech. Lateral Entry.</p>
                            </li>
                            <li>
                                <p>Both the SNET and the qualifying test results will be used in making the B.Tech. and
                                    B.Tech. Lateral Entry selections, respectively.</p>
                            </li>
                            <li>
                                <p>A candidate must have completed a 10+2 programme with the appropriate subject
                                    combinations and relevant percentage of marks in order to be eligible for admission
                                    to any of the medical courses within the bachelor's programme.</p>
                            </li>
                            <li>
                                Admission to a medical bachelor's programme is dependent on a candidate's performance in
                                a qualifying test; for MBBS and BDS, this is a legitimate NEET score.
                            </li>
                        </ul>
                        <h4>Method to apply for getting admission</h4>
                        <p>There are few options for submitting applications and getting admission to the institution.
                            Candidates have the option of selecting the best possible option. The application procedure
                            is outlined in full below.</p>
                        <ul>
                            <li>
                                <p>The application is available for download on the university's website. You must
                                    submit a properly filled-out form to the college. A Demand Draft (DD) for Rs.1,500
                                    payable to "Swami VivekanandSubharti University" will be drawn.</p>
                            </li>
                            <li><p>Another option is for the applicant to pay using NEFT/RTGS. Check the official
                                website for the account number.</p></li>
                            <li>
                                <p>
                                    The third method is for potential students to print out an application from the
                                    Central Admission Cell's website and mail it in with a check or money order.
                                </p>
                            </li>
                            <li>
                                <p>Another option is to apply directly to the institution and physically complete the
                                    application and requisite steps there at the point.</p>
                            </li>
                        </ul>
                        <p>After the application process is complete, the names of accepted students will be posted on
                            the university's website.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
