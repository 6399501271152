import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evalution-procedure',
  templateUrl: './evalution-procedure.component.html',
  styleUrls: ['./evalution-procedure.component.scss']
})
export class EvalutionProcedureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
